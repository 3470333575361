import { colors, opacity, weights } from '@components/theme/gen2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { EditableInputNew } from '@nx-fe/components';
import { rem, rgba } from 'polished';
import React from 'react';

export const StyledInviteRequest = React.memo(
  styled(Accordion)<{ $hasError?: boolean; }>(({ $hasError }) => ({
    boxShadow: 'none',
    border: `1px solid ${colors.grayLight1}`,

    '&.Mui-expanded': {
      margin: 0,
    },

    '.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },

    '&:not(:last-child)': {
      borderBottomColor: 'transparent',
    },

    '&:before': {
      display: 'none',
    },

    // has error
    ...(!!$hasError && {
      border: `${rem(1)} solid ${colors.danger}`,
      borderBottomColor: `${colors.danger} !important`,
    }),
  })),
);

export const StyledInviteRequestHead = React.memo(
  styled(AccordionSummary)({
    flexDirection: 'row-reverse',
    padding: rem(10),
    paddingLeft: rem(20),
    margin: 0,
    gap: rem(20),

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
      fontSize: rem(18),
      color: colors.grayDark1,
    },

    '& .MuiAccordionSummary-content': {
      margin: 0,
      alignItems: 'flex-end',
      gap: rem(10),
      overflow: 'scroll',

      // no scrollbar
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
);

export const StyledEditableInput = React.memo(
  styled(EditableInputNew)(() => ({
    border: 'none',
    marginRight: 'auto',
    overflow: 'hidden',
    width: rem(470),
    maxWidth: rem(576),
  })),
);

export const StyledTypesContainer = React.memo(
  styled('div')(({ theme }) => ({
    width: rem(175),
    [theme.breakpoints.down('sm')]: {
      width: rem(130),
    },
  })),
);

export const StyledTypes = React.memo(
  styled(Menu)({
    borderRadius: rem(99),
    paddingTop: rem(5),
    paddingBottom: rem(5),
    paddingLeft: rem(10),
    paddingRight: rem(10),
    marginTop: rem(10),
  }),
);

export const StyledTypeButton = styled(Button)({
  display: 'flex',
  gap: rem(7),
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export const StyledType = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
  minWidth: rem(115),
});

export const StyledInviteRequestBody = styled(AccordionDetails)({
  paddingTop: rem(20),
  borderTop: `1px solid ${colors.grayLight1}`,
  backgroundColor: colors.grayLight3,
});

export const StyledIconButton = styled(IconButton)({
  fontSize: rem(18),
  width: rem(36),
  height: rem(36),
});

export const StyledErrorBox = styled('div')({
  padding: `${rem(10)} ${rem(20)}`,
  color: colors.danger,
  fontWeight: weights.medium,
  backgroundColor: rgba(colors.danger, opacity._10),
});

export const StyledTitle = styled(TextField)({
  width: '100%',
  height: rem(40),
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',

  '& .MuiInputBase-input': {
    paddingTop: rem(10),
    marginBottom: rem(5),
  },
});
