import { useAuth } from '@gen2/hooks';
import { PermissionsKeys } from '@gen2/types/permissions';
import { useCallback } from 'react';
import { Link, LinkProps, Navigate } from 'react-router-dom';

type PermissionsProps = {
  children: JSX.Element;
  allow: PermissionsKeys[] | PermissionsKeys;
  exact?: boolean;
};

type UsePermissions = {
  withPermissions: (
    keys: PermissionsKeys[] | PermissionsKeys,
    exact?: boolean,
  ) => boolean;
};

export const usePermissions = (): UsePermissions => {
  const { permissions: p } = useAuth();

  const withPermissions = useCallback(
    (permissions: PermissionsKeys[] | PermissionsKeys, exact = false) => {
      if (!p) return false;

      if (!Array.isArray(permissions)) {
        return p[permissions];
      }

      if (exact) {
        return permissions.every((permission) => p[permission]);
      }

      return permissions.some((permission) => p[permission]);
    },
    [p],
  );

  return { withPermissions };
};

export const Permissions = ({
  children,
  allow,
  exact = false,
}: PermissionsProps) => {
  const { withPermissions } = usePermissions();

  if (!withPermissions(allow, exact)) {
    return null;
  }

  return children;
};

export const AuthorizedRoute = ({
  children,
  allow,
  exact = true,
}: PermissionsProps) => {
  const { withPermissions } = usePermissions();

  if (!withPermissions(allow, exact)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export interface AuthorizedLinkProps extends LinkProps {
  children: JSX.Element | React.ReactNode;
  allow: PermissionsKeys[] | PermissionsKeys;
  exact?: boolean;
}

export const AuthorizedLink = ({
  children,
  allow,
  exact = true,
  ...props
}: AuthorizedLinkProps) => {
  const { withPermissions } = usePermissions();

  if (!withPermissions(allow, exact)) {
    return children as JSX.Element;
  }

  return <Link {...props}>{children}</Link>;
};
