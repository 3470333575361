import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalCloseButton } from '@nx-fe/components';
import {
  Actions,
  Container,
  Content,
  StyledPopover,
  Tab,
  Tabs,
  Title,
} from './base-popup-with-tabs.styled';

export interface TabData {
  id: number;
  title: string;
  featureFlag?: string;
}

export interface BasicPopupWithTabsProps {
  open: boolean;
  id?: string;
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onPopoverOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  tabs?: TabData[];
  actionButton?: React.ReactNode;
  hasLeftActionButton?: boolean;
  tabValue?: number;
  handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
  renderPanel?: React.ReactNode;
}

export const BasePopupWithTabs = ({
  open,
  id,
  anchorEl,
  onClose,
  title = 'Add by',
  tabs = [],
  actionButton,
  hasLeftActionButton = false,
  tabValue,
  handleTabChange,
  renderPanel,
}: BasicPopupWithTabsProps) => {
  return (
    <StyledPopover
      data-cy={id}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <ModalCloseButton aria-label="close" onClick={onClose}>
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <Container>
        <Content>
          <Tabs>
            <Title>{title}</Title>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                data-cy={`tab-${tab.id}`}
                active={tabValue === tab.id}
                onClick={(e) => handleTabChange && handleTabChange(e, tab.id)}
              >
                <span>{tab.title}</span>
              </Tab>
            ))}
          </Tabs>
          {renderPanel}
        </Content>
        <Actions data-cy="footer" hasLeftActionButton={hasLeftActionButton}>
          {actionButton}
        </Actions>
      </Container>
    </StyledPopover>
  );
};
