import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth, useRouter } from '@gen2/hooks';
import { useSidebarStore } from '@gen2/hooks/stores';
import { Permissions } from '@gen2/utils/permissions/permissions';
import { Theme, Tooltip, useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { extras, mainMenu, MenuItem, subMenus } from './data';
import {
  StyledSidebar,
  StyledSidebarDivider,
  StyledSidebarList,
  StyledSidebarListItem,
  StyledSidebarListItemButton,
  StyledSidebarListItemLink,
  StyledSidebarMain,
  StyledSubMenu,
  StyledSubMenuClose,
} from './sidebar.styled';

export const Sidebar = () => {
  const store = useSidebarStore();
  const router = useRouter();
  const { featureFlags } = useAuth();
  const isBeyondMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm'),
  );

  const subMenuRef = useRef<HTMLDivElement>(null);
  const [subMenuWidth, setSubMenuWidth] = useState(0);

  const onItemClick = (item: MenuItem) => {
    store.setOpen(false);

    // NOTE:
    // best way to check the url later
    if (item.to === '/') return; // dashboard has no submenu

    if (store.subMenuOpen) {
      if (item.id === store.selected?.id) {
        store.setSubMenuOpen(false);
      } else {
        store.switchSubmenu(item);
      }
    } else {
      store.setSelected(item);
      store.setSubMenuOpen(true);
    }
  };

  const subMenuResizeHandler = () => {
    if (!subMenuRef.current) return;

    setSubMenuWidth(subMenuRef.current.offsetWidth);
  };

  useEffect(() => {
    store.setOpen(false);
    store.setSubMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useLayoutEffect(() => {
    if (!subMenuRef.current) return;

    const resizeObserver = new ResizeObserver(subMenuResizeHandler);

    resizeObserver.observe(subMenuRef.current);

    setSubMenuWidth(subMenuRef.current.offsetWidth);

    return () => {
      resizeObserver.disconnect();
    };
  }, [subMenuRef]);

  // Filter items based on feature flags
  const filteredmainMenu = useMemo(() => {
    return mainMenu.filter((item) => {
      // If no featureFlag, show by default
      if (!item.featureFlag) {
        return true;
      }

      // If featureFlag is an array, check if any of the flags are enabled
      if (Array.isArray(item.featureFlag)) {
        return item.featureFlag.some(
          (flag) => featureFlags?.[flag as keyof typeof featureFlags],
        );
      }

      // If featureFlag is a string, check if it's enabled
      return featureFlags?.[item.featureFlag as keyof typeof featureFlags];
    });
  }, [featureFlags]);

  return (
    <>
      <StyledSidebar
        className={cn({
          open: store.open || isBeyondMobile,
        })}
        data-cy="sidebar"
      >
        <StyledSidebarMain>
          <StyledSidebarList data-cy="sidebar-list">
            {filteredmainMenu.map((item) => (
              <Permissions key={item.title} allow={item.permission ?? []}>
                <StyledSidebarListItem data-cy="sidebar-list-item">
                  {item.to ? (
                    <StyledSidebarListItemLink
                      data-cy={`sidebar-list-item-link-${item?.title?.toLowerCase()}`}
                      to={item.to || ''}
                    >
                      <Tooltip title={item.title} placement="right">
                        <FontAwesomeIcon size="xl" icon={item.icon || 'x'} />
                      </Tooltip>
                      <span>{item.title}</span>
                    </StyledSidebarListItemLink>
                  ) : (
                    <StyledSidebarListItemButton
                      data-cy={`sidebar-list-item-btn-${item?.title?.toLowerCase()}`}
                      onClick={() => onItemClick(item)}
                    >
                      <Tooltip title={item.title} placement="right">
                        <FontAwesomeIcon size="xl" icon={item.icon || 'x'} />
                      </Tooltip>
                      <span>{item.title}</span>
                    </StyledSidebarListItemButton>
                  )}
                </StyledSidebarListItem>
              </Permissions>
            ))}
          </StyledSidebarList>
          <StyledSidebarList data-cy="sidebar-list-extras">
            {extras.map((item) => (
              <StyledSidebarListItem
                data-cy="sidebar-list-item"
                key={item.title}
              >
                {item.to ? (
                  <StyledSidebarListItemLink
                    data-cy={`sidebar-list-item-link-${item?.title?.toLowerCase()}`}
                    to={item.to || ''}
                  >
                    <Tooltip title={item.title} placement="right">
                      <FontAwesomeIcon size="xl" icon={item.icon || 'x'} />
                    </Tooltip>
                    <span>{item.title}</span>
                  </StyledSidebarListItemLink>
                ) : (
                  <StyledSidebarListItemButton
                    data-cy={`sidebar-list-item-btn-${item?.title?.toLowerCase()}`}
                    onClick={() => onItemClick(item)}
                  >
                    <Tooltip title={item.title} placement="right">
                      <FontAwesomeIcon size="xl" icon={item.icon || 'x'} />
                    </Tooltip>
                    <span>{item.title}</span>
                  </StyledSidebarListItemButton>
                )}
              </StyledSidebarListItem>
            ))}
          </StyledSidebarList>
          <StyledSidebarDivider />
        </StyledSidebarMain>
      </StyledSidebar>
      <StyledSubMenu
        $width={subMenuWidth}
        className={cn({ open: store.subMenuOpen })}
        ref={subMenuRef}
        data-cy="sidebar-submenu"
      >
        <>
          <StyledSubMenuClose
            className={cn({ hidden: !store.subMenuOpen })}
            onClick={() => store.setSubMenuOpen(false)}
            data-cy="sidebar-submenu-close"
          >
            <FontAwesomeIcon size="xs" icon={faClose} />
          </StyledSubMenuClose>
          {store.selected && subMenus[store.selected.id]}
        </>
      </StyledSubMenu>
    </>
  );
};
