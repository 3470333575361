import { colors } from '@components/theme/gen2';
import { styled, Typography } from '@mui/material';
import { rem } from 'polished';

export const StyledSkeletonContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  gridColumnGap: rem(10),
  paddingLeft: rem(15),
  paddingRight: rem(15),
});

export const Name = styled(Typography)({
  fontSize: rem(14),
  color: colors.grayDark3,
});

export const Contacts = styled(Typography)({
  fontSize: rem(12),
  color: colors.grayDark2,
});
