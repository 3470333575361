import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import { TMergeTemplates } from '@gen2/api/invites/api';
import {
  InvitesKeys,
  useMergeTemplatesMutation,
} from '@gen2/api/invites/hooks';
import { SetupPlugin } from '@gen2/app/components/rich-text-editor/plugins/SetupPlugin/SetupPlugin';
import RichTextEditor from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { $generateHtmlFromNodes } from '@lexical/html';
import { Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { LexicalEditor } from 'lexical';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { MAX_MESSAGE_LENGTH } from '../../../schema';
import { useMergeTemplateModalStore } from '../store';
import {
  ErrorMessage,
  FieldContainer,
  FieldLabel,
  FieldWrapper,
  FormContainer,
  OtherSettingsContainer,
  OtherSettingsDescription,
  RemindersWrapper,
  StyledFormControl,
} from './other-settings.styled';

type OtherSettingsForm = {
  subject: string;
  message: string;
  meta: string;
  reminders: string;
};

const schema = Yup.object().shape({
  subject: Yup.string().required(
    'mergeModal.otherSettings.form.subject.required',
  ),
  message: Yup.string().required(
    'mergeModal.otherSettings.form.message.required',
  ),
  reminders: Yup.string().required(
    'mergeModal.otherSettings.form.reminders.required',
  ),
});

const defaultValues = {
  subject: '',
  message: '',
  reminders: '',
};

export const OtherSettings = () => {
  const { t } = useTranslation('templates');
  const siStore = useSendInviteStore();
  const toast = useToast();
  const { mutateAsync } = useMergeTemplatesMutation();

  const {
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
  } = useForm<OtherSettingsForm>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const wSubjectTemplateId = watch('subject');
  const wMessageTemplateId = watch('message');
  const wRemindersTemplateId = watch('reminders');

  const {
    isSubmitting,
    templates,
    requests,
    selectedRequests,
    subject,
    message,
    reminderSettings,
    setIsSubmitting,
    setIsSubmitted,
    setSubject,
    setMessage,
    setReminderSettings,
  } = useMergeTemplateModalStore();

  useEffect(() => {
    if (subject || message || reminderSettings) {
      setValue('subject', subject || '');
      setValue('message', message || '');
      setValue('reminders', reminderSettings || '');
    }
  }, [message, reminderSettings, setValue, subject]);

  useEffect(() => {
    const sortSelectedRequests = async (requestToTemplateMap: { [key: string]: string; }) => {
      return selectedRequests?.slice().sort((a, b) => {
        const templateIdA = requestToTemplateMap[a];
        const templateIdB = requestToTemplateMap[b];

        const indexA = templates.findIndex(template => template.id === templateIdA);
        const indexB = templates.findIndex(template => template.id === templateIdB);

        return indexA - indexB;
      });
    };

    const sortTemplateRequests = async () => {
      const requestToTemplateMap: { [key: string]: string; } = {};

      for (const templateId in requests) {
        requests[templateId].forEach((requestId: string) => {
          requestToTemplateMap[requestId] = templateId;
        });
      }

      return sortSelectedRequests(requestToTemplateMap);
    };

    const handleErrorResponse = async (err: unknown) => {
      const error = err as AxiosResponse<{
        errors: { [index: string]: string[]; };
        message: string;
      }>;

      if (error.status === 422) {
        const responseErrors = error.data;

        toast.show({
          text: responseErrors?.message,
          variant: 'error',
        });
      } else {
        toast.show({
          text: t('failedToChange'),
          variant: 'error',
        });
      }

      setIsSubmitting(false);
    };

    const getTemplateId = (template?: string) => {
      return template !== 'no-template' ? template : '';
    };

    const validateForm = async () => {
      if (isSubmitting && requests) {
        const isValid = await trigger();

        if (!isValid) {
          setIsSubmitting(false);
          return;
        }

        let payloadSelectedRequests = selectedRequests;

        if (selectedRequests?.length === Object.values(requests).flat().length) {
          payloadSelectedRequests = await sortTemplateRequests();
        }

        const payload: TMergeTemplates = {
          inviteId: siStore.invite.id,
          templates: templates.map((template) => template.id),
          requests: payloadSelectedRequests || [],
          subject_template_id: getTemplateId(subject),
          message_template_id: getTemplateId(message),
          reminder_settings_template_id: getTemplateId(reminderSettings),
        };

        try {
          await mutateAsync(payload);

          await queryClient.invalidateQueries([
            InviteRequestsKeys.getInviteRequests,
          ]);
          await queryClient.invalidateQueries([InvitesKeys.getInvite]);

          toast.show({
            text: t('afterChange'),
            variant: 'success',
          });

          setIsSubmitting(false);
          setIsSubmitted(true);
        } catch (err: unknown) {
          await handleErrorResponse(err);
        } finally {
          if (siStore.invite?.id) {
            siStore.setContextInviteIdForNewInvite(siStore.invite.id);
          }
        }
      }
    };

    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  /**
   * Subject line
   */
  const [subjectTextFieldValue, setSubjectTextFieldValue] =
    useState<string>('');

  useEffect(() => {
    if (wSubjectTemplateId) {
      trigger('subject');

      if (wSubjectTemplateId === 'no-template') {
        setSubjectTextFieldValue('');
        setSubject(wSubjectTemplateId);
        return;
      }

      const filteredTemplate = templates.find(
        (template) => template?.id === wSubjectTemplateId,
      );

      if (filteredTemplate) {
        setSubjectTextFieldValue(filteredTemplate.subject || '');
        setSubject(filteredTemplate.id || '');

        return;
      }

      setSubjectTextFieldValue('');
    }
  }, [setSubject, setValue, templates, trigger, wSubjectTemplateId]);

  /**
   * Message
   */
  const [messageTextFieldValue, setMessageTextFieldValue] = useState<{
    message: string;
    meta: string;
  }>({ message: '', meta: '' });

  useEffect(() => {
    if (wMessageTemplateId) {
      trigger('message');

      if (wMessageTemplateId === 'no-template') {
        setMessageTextFieldValue({ message: '', meta: '' });
        setMessage(wMessageTemplateId);
        return;
      }

      const filteredTemplate = templates.find(
        (template) => template?.id === wMessageTemplateId,
      );

      if (filteredTemplate) {
        setMessageTextFieldValue({
          message: filteredTemplate.message || '',
          meta: filteredTemplate.meta || '',
        });

        setMessage(filteredTemplate.id || '');

        return;
      }

      setMessageTextFieldValue({ message: '', meta: '' });
    }
  }, [setMessage, templates, trigger, wMessageTemplateId]);

  /**
   * Reminders
   */
  const [remindersSelectedValue, setRemindersSelectedValue] = useState<{
    almost_due_reminder: number;
    overdue_reminder: number;
  }>({ almost_due_reminder: 0, overdue_reminder: 0 });

  useEffect(() => {
    if (wRemindersTemplateId) {
      trigger('reminders');

      if (wRemindersTemplateId === 'no-template') {
        setRemindersSelectedValue({
          almost_due_reminder: 0,
          overdue_reminder: 0,
        });
        setReminderSettings(wRemindersTemplateId);
        return;
      }

      const filteredTemplate = templates.find(
        (template) => template?.id === wRemindersTemplateId,
      );

      if (filteredTemplate) {
        setRemindersSelectedValue({
          almost_due_reminder: filteredTemplate.almost_due_reminder || 0,
          overdue_reminder: filteredTemplate.overdue_reminder || 0,
        });

        setReminderSettings(filteredTemplate.id || '');

        return;
      }

      setRemindersSelectedValue({
        almost_due_reminder: 0,
        overdue_reminder: 0,
      });
    }
  }, [setReminderSettings, templates, trigger, wRemindersTemplateId]);

  const onEditorSetup = (editor: LexicalEditor) => {
    const editorState = editor.getEditorState();

    editorState.read(() => {
      const rawHTML = $generateHtmlFromNodes(editor);

      setMessageTextFieldValue((prev) => {
        return {
          ...prev,
          message: rawHTML,
        };
      });
    });
  };

  return (
    <OtherSettingsContainer>
      <Box>
        <OtherSettingsDescription>
          {t('mergeModal.otherSettings.description')}
        </OtherSettingsDescription>
      </Box>

      <FormContainer>
        <FieldContainer>
          <FieldLabel>Subject line:</FieldLabel>
          <FieldWrapper>
            <StyledFormControl fullWidth error={Boolean(errors.subject)}>
              <InputLabel id="subject-line">Select Subject Line</InputLabel>
              <Select
                data-cy="subject-line-menu"
                inputProps={{
                  'data-cy': 'subject-line',
                }}
                value={subject}
                fullWidth
                {...register('subject')}
                label="Select Subject Line"
              >
                <MenuItem value={''} sx={{ display: 'none' }} />

                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}

                <MenuItem value="no-template">
                  {t('mergeModal.otherSettings.noTemplate')}
                </MenuItem>
              </Select>
              {errors.subject && (
                <ErrorMessage data-cy="subject-error">
                  {errors.subject?.message && t(errors.subject.message)}
                </ErrorMessage>
              )}
            </StyledFormControl>
            <Box sx={{ width: '100%' }}>
              <TextField
                fullWidth
                id="subject-line-input"
                value={subjectTextFieldValue}
                label="Subject Line"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </FieldWrapper>
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Message:</FieldLabel>
          <FieldWrapper>
            <StyledFormControl fullWidth error={Boolean(errors.message)}>
              <InputLabel id="message">Select Message</InputLabel>
              <Select
                data-cy="message-menu"
                inputProps={{
                  'data-cy': 'message',
                }}
                value={message}
                fullWidth
                {...register('message')}
                label="Select Message"
              >
                <MenuItem value={''} sx={{ display: 'none' }} />

                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}

                <MenuItem value="no-template">
                  {t('mergeModal.otherSettings.noTemplate')}
                </MenuItem>
              </Select>
              {errors.message && (
                <ErrorMessage data-cy="message-error">
                  {errors.message?.message && t(errors.message.message)}
                </ErrorMessage>
              )}
            </StyledFormControl>

            <Box sx={{ width: '100%' }}>
              <RichTextEditor
                placeholder="Message"
                withToolbar={false}
                isEditable={false}
              >
                <SetupPlugin
                  onSetup={onEditorSetup}
                  maxLength={MAX_MESSAGE_LENGTH}
                  defaultEditorState={messageTextFieldValue?.meta}
                />
              </RichTextEditor>
            </Box>
          </FieldWrapper>
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Reminder Settings:</FieldLabel>
          <FieldWrapper>
            <StyledFormControl fullWidth error={Boolean(errors.reminders)}>
              <InputLabel id="reminder-settings">
                Select Reminder Settings
              </InputLabel>
              <Select
                data-cy="reminder-settings-menu"
                inputProps={{
                  'data-cy': 'reminder-settings',
                }}
                value={reminderSettings}
                fullWidth
                {...register('reminders')}
                label="Select Reminder Settings"
              >
                <MenuItem value={''} sx={{ display: 'none' }} />

                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}

                <MenuItem value="no-template">
                  {t('mergeModal.otherSettings.noTemplate')}
                </MenuItem>
              </Select>
              {errors.reminders && (
                <ErrorMessage data-cy="reminders-error">
                  {errors.reminders?.message && t(errors.reminders.message)}
                </ErrorMessage>
              )}
            </StyledFormControl>

            <RemindersWrapper>
              <Box sx={{ width: '50%' }}>
                <span>
                  Almost due reminder:{' '}
                  {remindersSelectedValue.almost_due_reminder || '-'}
                </span>
              </Box>
              <Box sx={{ width: '50%' }}>
                <span>
                  Overdue reminder:{' '}
                  {remindersSelectedValue.overdue_reminder || '-'}
                </span>
              </Box>
            </RemindersWrapper>
          </FieldWrapper>
        </FieldContainer>
      </FormContainer>
    </OtherSettingsContainer>
  );
};
