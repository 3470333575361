import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import { TTemplate } from '@gen2/api/templates/api';
import { useGetTemplateRequestsQuery } from '@gen2/api/templates/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMergeTemplateModalStore } from '../store';
import { CardContentContainer, CardContentRequestContainer, CardContentRequestHeader, CardContentRequestMessage, HeaderText, IconContainer, RequestContainer, RequestIcon, RequestTitle, StyledCheckbox, TemplateCardContainer, TemplateCardHeader } from './template-card.styled';


interface TemplateCardProps {
  template: TTemplate;
}

export const TemplateCard = ({ template }: TemplateCardProps) => {
  const { t } = useTranslation('templates');

  const {
    selectedRequests,
    setAllRequestIds,
    setSelectedRequestIds,
    setPerTemplateSelectedCount,
    appendSelectedRequestIds
  } = useMergeTemplateModalStore();

  const templateId = template.id;

  const { data: templateRequests } = useGetTemplateRequestsQuery(templateId);

  const [templateRequestIds, setTemplateRequestIds] = useState<string[]>([]);

  useEffect(() => {
    if (templateRequests) {
      const templateRequestIdsResponse = templateRequests.map((request: TInviteRequest) => request.id);

      setAllRequestIds({ [templateId]: templateRequestIdsResponse });

      if (
        selectedRequests?.some((requestId) => templateRequestIdsResponse.includes(requestId))
      ) {
        const filteredTemplateRequestIds = templateRequestIdsResponse?.filter((id) => selectedRequests.includes(id)) || [];

        setTemplateRequestIds(filteredTemplateRequestIds);

        return;
      }

      if (templateRequestIdsResponse.length < 1 && (selectedRequests || []).length > 0) {
        return;
      }

      setTemplateRequestIds(templateRequestIdsResponse);
      appendSelectedRequestIds(templateRequestIdsResponse);
      setPerTemplateSelectedCount({ [templateId]: templateRequestIdsResponse.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateRequests]);

  const handleCheckboxChange = (requestId: string) => {
    if (selectedRequests?.includes(requestId)) {
      const filteredSelectedRequestIds = selectedRequests.filter((id) => id !== requestId);
      const filteredTemplateRequestIds = templateRequestIds?.filter((id) => id !== requestId) || [];

      setTemplateRequestIds(filteredTemplateRequestIds);
      setSelectedRequestIds(filteredSelectedRequestIds);
      setPerTemplateSelectedCount({ [templateId]: filteredTemplateRequestIds.length });
    } else {
      setTemplateRequestIds((prev) => [...prev, requestId]);
      appendSelectedRequestIds([requestId]);
      setPerTemplateSelectedCount({ [templateId]: templateRequestIds.length + 1 });
    }
  };

  const handleHeaderCheckboxChange = () => {
    const templateRequestIdsResponse = templateRequests?.map((request: TInviteRequest) => request.id) || [];

    if (templateRequests?.length === templateRequestIds.length) {
      const filteredSelectedRequests = (selectedRequests || []).filter((req) => !templateRequestIdsResponse.includes(req));
      setTemplateRequestIds([]);
      setSelectedRequestIds(filteredSelectedRequests);
      setPerTemplateSelectedCount({ [templateId]: 0 });
    } else {
      setTemplateRequestIds(templateRequestIdsResponse);
      appendSelectedRequestIds(templateRequestIdsResponse);
      setPerTemplateSelectedCount({ [templateId]: templateRequestIdsResponse.length });
    };
  };

  const handleRequestIcons = useCallback(
    (type: string) => {
      switch (type) {
        case 'file-upload':
          return regular('upload');
        case 'written-response':
          return regular('file-alt');
        case 'read-only':
          return regular('eye');
        default:
          return regular('pen-alt');
      }
    },
    []
  );

  if (!template) {
    return null;
  }

  return (
    <TemplateCardContainer>
      <TemplateCardHeader>
        <HeaderText>{template.name}</HeaderText>
      </TemplateCardHeader>

      <CardContentContainer>
        <p>{t('mergeModal.selectRequests')}</p>
        <CardContentRequestContainer>
          <CardContentRequestHeader>
            <CardContentRequestMessage>{t('mergeModal.requestColumn')}</CardContentRequestMessage>

            <StyledCheckbox color='secondary'
              indeterminate={templateRequestIds.length > 0 && templateRequestIds.length < (templateRequests?.length ?? 0)}
              checked={templateRequests?.length !== 0 && templateRequests?.length === templateRequestIds.length}
              onClick={handleHeaderCheckboxChange}
            />
          </CardContentRequestHeader>
          {
            templateRequests?.map((request, index) => (
              <RequestContainer key={request.id} data-cy={`template-request-${request.id}`}>
                <IconContainer>
                  <RequestIcon icon={handleRequestIcons(request.type)} />
                </IconContainer>

                <RequestTitle>{request.title}</RequestTitle>

                <StyledCheckbox color='secondary'
                  checked={selectedRequests?.includes(request.id)}
                  onClick={() => handleCheckboxChange(request.id)}
                />
              </RequestContainer>
            ))
          }
        </CardContentRequestContainer>
      </CardContentContainer>
    </TemplateCardContainer>
  );
};
