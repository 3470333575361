import {
  useDeleteFilesMutation,
  useUploadFileMutation,
} from '@gen2/api/files/hooks';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TInviteRequestFile } from '@gen2/api/invite-requests/api';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import { TemplateKeys } from '@gen2/api/templates/hooks';
import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import { useFileStore } from '@gen2/app/components/invite-request/hooks/file-store';
import { SetupPlugin } from '@gen2/app/components/rich-text-editor/plugins/SetupPlugin/SetupPlugin';
import RichTextEditor from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { UpgradeModal, useFollowUpStore } from '@gen2/app/follow-up/store';
import { TInviteRequestsForm } from '@gen2/app/invites/send-invites/requests/requests';
import { MAX_MESSAGE_LENGTH } from '@gen2/app/invites/send-invites/requests/schema';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useTemplateFormStore } from '@gen2/app/templates/template-form/store';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { Alert, FormHelperText, Stack } from '@mui/material';
import {
  allowedFileTypes,
  DragAndDrop,
  FileUpload,
  formatSize,
  renderIcon,
} from '@nx-fe/components';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import { TRequestContainer, useRequestHook } from '../../hooks/request';
import { RequestContacts } from '../../request-contact/request-contact';
import {
  StyledAttachments,
  StyledAttachmentsLabel,
  StyledFileUploadRequest,
} from './file-upload.styled';
import {
  fileValidationSchema,
  MAX_FILE_COUNT,
  MAX_FILE_SIZE_IN_MB,
} from './schema';

export type TFileUploadRequestForm = {
  message: string;
  meta: string;
};

export type TFileUploadRequestProps = {
  id: number;
  container?: TRequestContainer;
};

export const FileUploadRequest = React.memo(
  ({ id, container = 'invite' }: TFileUploadRequestProps) => {
    const {
      setValue,
      formState: { errors },
    } = useFormContext<TInviteRequestsForm>();
    const fileTypes = allowedFileTypes();
    const fileSize = MAX_FILE_SIZE_IN_MB / 1024 / 1024;

    const siStore = useSendInviteStore();
    const { mutate: fileUploadMutation } = useUploadFileMutation();
    const [fileErrors, setFileErrors] = useState<string[]>();
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const { isLoading: isFileDeleting } = useDeleteFilesMutation();
    const { setIsConfirmationModalOpen } = useConfirmationModalStore();
    const { t } = useTranslation('sendInvite');
    const { onUpdate, req } = useRequestHook({ id, container });
    const { setFile } = useFileStore();
    const followUp = useFollowUpStore();
    const template = useTemplateFormStore();
    const toast = useToast();

    const attachments = useMemo(() => {
      if (!req.files) return [];

      return req.files.filter((file) => file.type === 'send_invite_attachment');
    }, [req.files]);

    const onMessageChange = (
      editorState: EditorState,
      editor: LexicalEditor,
    ) => {
      editorState.read(() => {
        const root = $getRoot();

        const rawText = root.getTextContent();
        const rawHTML = $generateHtmlFromNodes(editor);

        // if no text set back to empty
        if (rawText.length === 0) {
          setValue(`requests.${id}.description`, '');
          setValue(`requests.${id}.meta`, '');
        } else {
          setValue(`requests.${id}.description`, rawHTML);
          setValue(`requests.${id}.meta`, JSON.stringify(editorState.toJSON()));
        }
      });
    };

    const onEditorSetup = (editor: LexicalEditor) => {
      const editorState = editor.getEditorState();

      editorState.read(() => {
        const rawHTML = $generateHtmlFromNodes(editor);

        setValue(`requests.${id}.description`, rawHTML);
      });
    };

    const onFileUpload = async (files: File[]) => {
      setIsUploadLoading(true);

      if (req?.id === undefined) return;

      setFileErrors([]);

      const hasErrors = await validateFiles(files);

      if (!hasErrors) {
        await uploadFiles(files);
      }
    };

    const validateFiles = useCallback(
      async (files: File[]): Promise<boolean> => {
        const totalCount = (attachments.length || 0) + (files?.length || 0);
        let hasErrors = false;

        if (totalCount > MAX_FILE_COUNT) {
          const upgradeModal: UpgradeModal = {
            isOpen: true,
            title: t('request.fileUpload.upgrade.title') ?? '',
            description: t('request.fileUpload.upgrade.description') ?? '',
          };

          if (container === 'invite') {
            siStore.setUpgradeModal(upgradeModal);
            followUp.setUpgradeModal(upgradeModal);
          }

          if (container === 'template') {
            template.setUpgradeModal(upgradeModal);
          }

          setIsUploadLoading(false);
          hasErrors = true;
        } else {
          try {
            await Promise.all(
              files.map(async (file) => {
                await fileValidationSchema.validate(
                  { file },
                  { abortEarly: false },
                );
              }),
            );
          } catch (err: unknown) {
            if (err instanceof ValidationError) {
              setFileErrors(err.errors);
              hasErrors = true;
            }
            setIsUploadLoading(false);
          }
        }

        return hasErrors;
      },
      [attachments.length, siStore, t],
    );

    const uploadFiles = useCallback(
      async (files: File[]): Promise<void> => {
        const successMessage =
          files.length > 1 ? 'uploaded_multiple' : 'uploaded';

        files.forEach(async (file, index) => {
          fileUploadMutation(
            {
              type: 'request',
              typeId: req.id,
              file,
            },
            {
              onSuccess: () => {
                if (index === files.length - 1) {
                  toast.show({
                    text:
                      t(`request.fileUpload.message.${successMessage}`) ?? '',
                    variant: 'success',
                  });

                  if (container === 'invite') {
                    queryClient.invalidateQueries([
                      InviteRequestsKeys.getInviteRequests,
                    ]);
                    queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);
                    queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);
                  }

                  if (container === 'template') {
                    queryClient.invalidateQueries([
                      TemplateKeys.getTemplateRequests,
                    ]);
                  }
                }
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onError: (err: any) => {
                toast.show({
                  variant: 'error',
                  text: err.data.message,
                });
              },
              onSettled: () => setIsUploadLoading(false),
            },
          );
        });
      },
      [fileUploadMutation, req?.id, siStore, t],
    );

    const contacts = useMemo(() => {
      if (req.contacts) return req.contacts;

      return [];
    }, [req]);

    const clearError = (errorMessage: string) => {
      setFileErrors((prev) => prev?.filter((err) => err !== errorMessage));
    };

    const handleDelete = (file: TInviteRequestFile) => {
      if (isFileDeleting) return;

      setFile({
        ...file,
        request: req,
      });

      setIsConfirmationModalOpen(true);
    };

    return (
      <StyledFileUploadRequest key={req.id}>
        {container === 'invite' && (
          <RequestContacts request={req} contacts={contacts} />
        )}

        <div>
          <RichTextEditor
            onBlur={onUpdate}
            placeholder={t('request.fileUpload.message.placeholder') ?? ''}
          >
            <OnChangePlugin onChange={onMessageChange} />
            <SetupPlugin
              onSetup={onEditorSetup}
              maxLength={MAX_MESSAGE_LENGTH}
              defaultEditorState={req?.meta}
            />
          </RichTextEditor>
          {(errors?.requests && errors?.requests[id])?.description?.message && (
            <FormHelperText error>
              {t(errors.requests[id]?.description?.message ?? '')}
            </FormHelperText>
          )}
        </div>
        {!!attachments.length && (
          <div>
            <StyledAttachmentsLabel>
              <span>Attachments</span>
              <span data-cy="number-of-file">{attachments.length} file(s)</span>
            </StyledAttachmentsLabel>
            <StyledAttachments>
              {req.files
                .filter((file) => file.type === 'send_invite_attachment')
                .map((file) => (
                  <FileUpload
                    key={file.id}
                    progress={100}
                    title={file.original_name}
                    fileSize={`(${formatSize(file.size)})`}
                    isUploading={false}
                    icon={renderIcon(file.mime_type)}
                    isDeleting={isFileDeleting}
                    onDelete={() => handleDelete(file)}
                    data-cy={`file-${file.id}`}
                  />
                ))}
            </StyledAttachments>
          </div>
        )}
        <Stack rowGap={2}>
          {!!fileErrors?.length &&
            fileErrors.map((err) => (
              <Alert
                data-cy="file-upload-error"
                key={err}
                severity="error"
                onClose={() => clearError(err)}
              >
                {t(`${err}`, { fileTypes, fileSize })}
              </Alert>
            ))}
          <DragAndDrop
            isLoading={isUploadLoading}
            onUpload={onFileUpload}
            accept=".png,.jpg,.jpeg,.pdf,.csv,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.heic,.heif"
          />
        </Stack>
      </StyledFileUploadRequest>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);
