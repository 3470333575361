import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TAddContactErrorResponse } from '@gen2/api/invite-contacts/api';
import {
  ContactKeys,
  useAddContactMutation,
} from '@gen2/api/invite-contacts/hooks';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import {
  InvitesKeys,
  useAddContactGroupsToInviteMutation,
  useAddContactsToInviteMutation,
} from '@gen2/api/invites/hooks';
import AddContactPopup, {
  AddContactPopupProps,
} from '@gen2/app/components/contact-popup/add-contact/add-contact';
import { ContactPopUp } from '@gen2/app/components/contact-popup/contact-popup';
import { queryClient } from '@gen2/config';
import { TAddContactForm, TContact } from '@gen2/types/contact';
import { TContactGroup } from '@gen2/types/contact-group';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { createContactPayload } from './helpers';
import { MAX_CONTACT_LIMIT } from './schema';
import { TDraftInviteForm } from './send-invites';
import { useSendInviteStore } from './store';

export const ContactPopups = () => {
  const params = useParams<{ id: string }>();
  const store = useSendInviteStore();
  const currentInviteId = useMemo(() => {
    // for new invite draft & updating invite both.
    return store.contextInviteIdForNewInvite || params.id || '';
  }, [params, store.contextInviteIdForNewInvite]);

  const { watch } = useFormContext<TDraftInviteForm>();
  const {
    mutate: addContactsToInviteMutation,
    isLoading: isAddContactsToInviteLoading,
  } = useAddContactsToInviteMutation();

  const {
    mutate: addContactGroupsToInviteMutation,
    isLoading: isAddContactGroupsToInviteLoading,
  } = useAddContactGroupsToInviteMutation();
  const { mutate: addContactMutation, isLoading: isAddContactLoading } =
    useAddContactMutation();

  const { t } = useTranslation('sendInvite');

  const wContacts = watch('contacts');

  const sendToIds = useMemo(() => {
    if (!wContacts) return [];

    return wContacts.map((item) => item.id);
  }, [wContacts]);

  const onCloseContactList = () => {
    store.setIsContactListOpen(false);
  };

  const openContact = (
    e: React.MouseEvent,
    mode: 'add' | 'edit',
    contact: TContact | null,
  ) => {
    e.stopPropagation();
    store.setisContactOpen(true);
    // remains open
    store.setIsContactListOpen(true);
    store.setMode(mode);
    store.setCurrentContact(contact);
  };

  const closeContact = () => {
    store.setisContactOpen(false);
  };

  const selectContact = useCallback(
    (contact: TContact) => {
      const newContacts = [...(wContacts || []), contact];
      const currentContactsLength = newContacts.length;

      // if the user has reached the limit of contacts he can send to
      // we open the upgrade modal
      if (currentContactsLength > MAX_CONTACT_LIMIT) {
        store.setUpgradeModal({
          isOpen: true,
          title: t('contacts.upgrade.title') ?? '',
          description: t('contacts.upgrade.description') ?? '',
        });
        return;
      } else {
        // the Contact Selection Modal remains open
        store.setIsContactListOpen(true);
        store.set({ isSendFromLoading: true });
        addContactsToInviteMutation(
          {
            inviteId: currentInviteId,
            contact_ids: [contact.id],
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([InvitesKeys.getInvite]);
              await queryClient.invalidateQueries([
                InviteRequestsKeys.getInviteRequests,
              ]);
              await queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);

              store.set({ isSendFromLoading: false });
              store.setBannerMessage({
                severity: 'success',
                message: store.isEditSendInvite
                  ? t('editSendInvite.addContactToEditInvite')
                  : '',
              });
            },
            onError: () => {
              store.set({ isSendFromLoading: false });
            },
          },
        );
      }
    },
    [addContactsToInviteMutation, currentInviteId, store, t, wContacts],
  );

  const selectContactGroups = useCallback(
    (contactGroup: TContactGroup) => {
      // the Contact Selection Modal remains open
      store.setIsContactListOpen(true);
      store.set({ isSendFromLoading: true });

      if (!contactGroup.contacts.length) {
        store.setUpgradeModal({
          isOpen: true,
          title: t('contacts.emptyGroup.title') ?? '',
          description: t('contacts.emptyGroup.description') ?? '',
        });
        store.set({ isSendFromLoading: false });
        return;
      }

      addContactGroupsToInviteMutation(
        {
          inviteId: currentInviteId,
          contact_group_ids: [contactGroup.id],
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([InvitesKeys.getInvite]);
            await queryClient.invalidateQueries([
              InviteRequestsKeys.getInviteRequests,
            ]);
            await queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);

            store.set({ isSendFromLoading: false });
            store.setBannerMessage({
              severity: 'success',
              message: store.isEditSendInvite
                ? t('editSendInvite.addContactToEditInvite')
                : '',
            });
          },
          onError: () => {
            store.set({ isSendFromLoading: false });
            store.setUpgradeModal({
              isOpen: true,
              title: t('contacts.upgrade.title') ?? '',
              description: t('contacts.upgrade.description') ?? '',
            });
          },
        },
      );
    },
    [addContactGroupsToInviteMutation, currentInviteId, store, t],
  );

  const resolveContactList = (contacts: TContact[]) => {
    if (!contacts.length) return [];

    return contacts.map((contact) => {
      if (sendToIds.includes(contact.id)) {
        return { ...contact, disabled: true };
      }
      return contact;
    });
  };

  const onCreateContact: AddContactPopupProps['onCreate'] = ({
    contact,
    form,
  }) => {
    addContactMutation(createContactPayload(contact), {
      onSuccess: async (res): Promise<void> => {
        const { contact } = res.data.data;

        // pre-select contact
        selectContact(contact);
        store.setPrePopulatedEmail('');
        closeContact();

        await queryClient.invalidateQueries({
          queryKey: [ContactKeys.getContacts],
        });
      },
      onError: (error: unknown) => {
        const {
          data: { errors },
        } = error as { data: TAddContactErrorResponse };

        if (errors) {
          Object.keys(errors).forEach((key) => {
            const perFieldErrors =
              errors[key as keyof TAddContactErrorResponse['errors']];

            if (!perFieldErrors) return;

            form.setError(key as keyof TAddContactForm, {
              type: 'manual',
              message: perFieldErrors[0],
            });
          });
        }
      },
    });
  };

  // there is no edit contact in this popup
  // const onEditContact: AddContactPopupProps['onEdit'] = ({ contact, form }) => {
  //   editContactMutation(
  //     {
  //       contact_id: store.currnetContact?.id ?? '',
  //       data: createContactPayload(contact),
  //     },
  //     {
  //       onSuccess: async () => {
  //         closeContact();

  //         // invalidate getContacts
  //         await queryClient.invalidateQueries({
  //           queryKey: [ContactKeys.getContacts],
  //         });
  //         await queryClient.invalidateQueries({
  //           queryKey: [InvitesKeys.getInvite],
  //         });
  //       },
  //       onError: (error: unknown) => {
  //         const { data } = error as { data: TAddContactErrorResponse };

  //         if (data?.errors) {
  //           const errors = data.errors;

  //           Object.keys(errors).forEach((key) => {
  //             const fieldErrors =
  //               errors[key as keyof TAddContactErrorResponse['errors']];

  //             if (!fieldErrors) return;

  //             form.setError(key as keyof TAddContactForm, {
  //               type: 'manual',
  //               message: fieldErrors[0],
  //             });
  //           });
  //         }
  //       },
  //     },
  //   );
  // };

  return (
    <>
      <ContactPopUp
        anchorEl={store.contactPopupAnchorEl}
        id="contact-list"
        open={store.isContactListOpen}
        onOpenContact={openContact}
        onSelectContact={selectContact}
        onSelectContactGroup={selectContactGroups}
        onClose={onCloseContactList}
        resolveItems={resolveContactList}
        loading={
          isAddContactsToInviteLoading || isAddContactGroupsToInviteLoading
        }
      />
      <AddContactPopup
        onCreate={onCreateContact}
        id="contact-create"
        open={store.isContactOpen}
        onClose={closeContact}
        isAddContactLoading={isAddContactLoading}
        mode={store.mode}
        prePopulatedEmail={store.prePopulatedEmail}
      />
    </>
  );
};
