import { useGetTemplateQuery } from '@gen2/api/templates/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { templateFormSchema } from './schema';
import { useTemplateFormStore } from './store';
import { Container, TemplateFormBody } from './template-form.styled';
import { TemplateHead } from './template-head/template-head';
import { TemplateMessage } from './template-message/template-message';
import { TemplateRequests } from './template-requests/template-requests';

export type TTemplateForm = {
  name: string;
  subject?: string;
  message?: string;
  rawMessage?: string;
  meta?: string;
  overdue_reminder?: string;
  almost_due_reminder?: string;
  customAlmostDueValue?: string;
  customOverdueValue?: string;
  status?: string;
};

export const defaultValues: TTemplateForm = {
  name: '',
  subject: '',
  message: '',
  meta: '',
  rawMessage: '',
  overdue_reminder: '4',
  almost_due_reminder: '1',
  customAlmostDueValue: '',
  customOverdueValue: '',
  status: 'draft',
};

export const TemplateForm = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const { data } = useGetTemplateQuery(templateId ?? '');
  const { setTemplate } = useTemplateFormStore();

  const methods = useForm<TTemplateForm>({
    defaultValues,
    resolver: yupResolver(templateFormSchema),
  });

  useEffect(() => {
    if (!data) return;

    methods.reset({
      name: data.name,
      subject: data.subject,
      message: data.message,
      overdue_reminder: data.overdue_reminder,
      almost_due_reminder: data.almost_due_reminder,
      status: data.status,
    });

    setTemplate(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, methods.reset]);

  return (
    <FormProvider {...methods}>
      <Container>
        <TemplateHead />
        <TemplateFormBody>
          <TemplateMessage />
          <TemplateRequests />
        </TemplateFormBody>
      </Container>
    </FormProvider>
  );
};
