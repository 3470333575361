import '@fontsource/poppins/300-italic.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';

export const headingType = 'Montserrat';
export const baseType = 'Poppins';

export const headingSizes = {
  h1: 24,
  h2: 24,
  h3: 20,
  h3Icon: 20,
  h4: 20,
  h5: 18,
  h6: 15,
};

export const textSizes = {
  base: 14,
  sm: 12,
  xs: 11,
  sm1: 13,
  md: 15,
  lg: 16,
  xl: 18,
  xxl: 20,
};

export const weights = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const typography = {
  fontFamily: [baseType, 'sans-serif'].join(','),
};
