import { TContact } from '@gen2/types/contact';
import { Creator } from '@gen2/types/invite';
import { TUser } from '@gen2/types/user';
import { ReactElement } from 'react';
import { stringAvatar } from '../../../utils/name';
import {
  AvatarBox,
  DetailsBox,
  ImageAvatar,
  ImageIconAvatar,
  StringAvatarBox,
  TextAvatar,
} from './avatar.styled';

export const handleAvatar = (user?: TUser | Creator | null) => {
  let result: string | ReactElement;

  if (user?.avatar_url) {
    result = <ImageAvatar imageurl={user?.avatar_url} />;
  } else {
    result = (
      <TextAvatar>{stringAvatar(user?.first_name, user?.last_name)}</TextAvatar>
    );
  }

  return result;
};

export const AvartarWithNameEmail = (contact: TContact) => {
  return (
    <AvatarBox>
      {contact?.avatar_url ? (
        <ImageIconAvatar src={contact.avatar_url} data-cy="image-avatar" />
      ) : (
        <StringAvatarBox data-cy="string-avatar">
          {stringAvatar(contact?.first_name, contact?.last_name)}
        </StringAvatarBox>
      )}
      <DetailsBox data-cy="name">
        {contact?.first_name} {contact?.last_name}
        <span>{contact?.email}</span>
      </DetailsBox>
    </AvatarBox>
  );
};
