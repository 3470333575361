import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SetupPlugin } from '@gen2/app/components/rich-text-editor/plugins/SetupPlugin/SetupPlugin';
import RichTextEditor from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { Button, FormHelperText } from '@mui/material';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledTemplateButtonContainer } from './editor.styled';
import { useSendInvite } from './hooks';
import { SendInviteRequests } from './requests/requests';
import { MAX_MESSAGE_LENGTH } from './schema';
import { TDraftInviteForm } from './send-invites';
import { useSendInviteStore } from './store';
import { StyledDiv, TabPanel } from './tab-panel/tab-panel';

export const Editor = () => {
  const store = useSendInviteStore();
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<TDraftInviteForm>();
  const { t } = useTranslation('sendInvite');
  const { onSave } = useSendInvite();
  const params = useParams<{ id: string }>();
  const siStore = useSendInviteStore();
  // for new invite draft & updating invite both
  const isInviteIdReady = !!siStore.contextInviteIdForNewInvite || params.id;

  const onMessageChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const root = $getRoot();

      const rawText = root.getTextContent();
      const rawHTML = $generateHtmlFromNodes(editor);

      // if no text set back to empty
      if (rawText.length === 0) {
        setValue('message', '');
        setValue('meta', '');
      } else {
        setValue('message', rawHTML);
        setValue('meta', JSON.stringify(editorState.toJSON()));
      }

      setValue('rawMessage', rawText);

      if (rawText) {
        trigger('rawMessage');
      }
    });
  };

  const onSetup = (editor: LexicalEditor) => {
    const editorState = editor.getEditorState();

    editorState.read(() => {
      const root = $getRoot();

      const rawText = root.getTextContent();
      const rawHTML = $generateHtmlFromNodes(editor);

      setValue('rawMessage', rawText);
      setValue('message', rawHTML);
    });
  };

  return (
    <TabPanel value={store.activeTabIndex} index={0}>
      <StyledDiv $opaque={!isInviteIdReady}>
        {!siStore.isEditSendInvite && (
          <StyledTemplateButtonContainer>
            <FontAwesomeIcon icon={solid('arrow-down')} />
            <span>Start from scratch or...</span>
            <Button
              onClick={() => siStore.setIsTemplatesOpen(true)}
              size="small"
              color="tertiary"
              variant="outlined"
            >
              Load Template
            </Button>
          </StyledTemplateButtonContainer>
        )}

        <RichTextEditor
          dataCy="invite-rich-text"
          label="Message to client"
          placeholder="Explain the purpose of this Invite, its Requests and any other helpful information."
          onBlur={onSave}
        >
          <OnChangePlugin onChange={onMessageChange} />
          <SetupPlugin
            onSetup={onSetup}
            defaultEditorState={store.invite.meta}
            maxLength={MAX_MESSAGE_LENGTH}
          />
        </RichTextEditor>
        {errors.rawMessage?.message && (
          <FormHelperText error>{t(errors.rawMessage?.message)}</FormHelperText>
        )}
      </StyledDiv>
      <SendInviteRequests />
    </TabPanel>
  );
};
