import { config } from '@gen2/config';
import { TContact } from '@gen2/types/contact';
import { TPhone } from '@gen2/types/phone';
import { SortColumn, SortOrder } from '@gen2/types/search-condition';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';
import { TContactResponse } from './hooks';

export type TContactParams = {
  page?: number;
  per_page?: number;
  ['filter[full_name]']?: string;
  ['filter[search]']?: string;
  ['sort[column]']?: SortColumn;
  ['sort[order]']?: SortOrder;
};

export const getContacts = (
  params?: TContactParams,
): AxiosPromise<TContactResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/contacts`,
    params: {
      page: 1,
      per_page: 25,
      ...params,
    },
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TAddContactRequest = {
  first_name: string;
  last_name: string;
  email: string;
  phone?: Omit<TPhone, 'id'>;
};

export type TAddContactResponse = {
  data: {
    contact: TContact;
  };
  message: string;
};

export type TAddContactErrorResponse = {
  errors: {
    first_name?: string[];
    last_name?: string[];
    email?: string[];
  };
  message: string;
};

export const addContact = (
  data: TAddContactRequest,
): AxiosPromise<TAddContactResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/contacts`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type EditContactPayload = {
  contact_id: string;
  data: TAddContactRequest;
};

export const editContact = ({
  data,
  contact_id,
}: EditContactPayload): AxiosPromise<TAddContactResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/contacts/${contact_id}`,
    data,
    method: 'put',
  });
};
