import { Invite } from '@gen2/types/invite';
import { useMutation } from '@tanstack/react-query';
import { deleteArchiveInvites, deleteDraftInvites, deleteInvites } from './api';

export enum Keys {
  DELETE_INVITES = 'deleteInvites',
  DELETE_DRAFT_INVITES = 'deleteDraftInvites',
  DELETE_ARCHIVE_INVITES = 'deleteArchiveInvites',
}

export interface InvitesListResponse {
  data: { invites: Invite[] };
  links: {
    first: string | null;
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

export const useDeleteInvitesMutation = () =>
  useMutation({
    mutationKey: [Keys.DELETE_INVITES],
    mutationFn: deleteInvites,
  });

export const useDeleteDraftInvitesMutation = () =>
  useMutation({
    mutationKey: [Keys.DELETE_DRAFT_INVITES],
    mutationFn: deleteDraftInvites,
  });

export const useDeleteArchiveInvitesMutation = () =>
  useMutation({
    mutationKey: [Keys.DELETE_ARCHIVE_INVITES],
    mutationFn: deleteArchiveInvites,
  });
