import { colors, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledUnauthorized = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  backgroundColor: colors.grayLight2,
  paddingLeft: rem(40),
  paddingRight: rem(40),
});

export const Container = styled('div')({
  maxWidth: rem(540),
  padding: `${rem(40)} ${rem(80)}`,
  backgroundColor: colors.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const Logo = styled('img')({
  marginBottom: rem(16),
});

export const Heading = styled('h1')({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
  margin: 0,
});

export const Divider = styled('hr')({
  width: '100%',
  height: rem(1),
  backgroundColor: colors.grayLight2,
  border: 'none',
  margin: `${rem(20)} 0`,
});

export const Content = styled('p')({
  marginBottom: rem(30),
  fontSize: rem(16),
});
