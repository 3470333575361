import { colors, textSizes, weights } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, styled } from '@mui/material';
import { rem } from 'polished';

export const TemplateCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: rem(380),
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: rem(4),
  border: '1px solid #D9DEE4',
  height: rem(703),

  [theme.breakpoints.down('md')]: {
    width: rem(400),
  },
}));

export const TemplateCardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: rem(72),
  gap: rem(15),
  padding: `${rem(16)} ${rem(30)}`,
  flexShrink: 0,
  alignItems: 'center',
  alignSelf: 'stretch',
  backgroundColor: colors.grayLight3,
  borderBottom: `1px solid ${colors.grayLight1}`,
  overflowY: 'auto'
}));

export const HeaderText = styled('span')(({ theme }) => ({
  fontSize: textSizes.xxl,
  fontWeight: weights.semiBold,
  color: colors.grayDark3
}));

export const CardContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: `${rem(20)} ${rem(24)}`,
  gap: rem(18),
  overflowY: 'auto',
  maxHeight: rem(700)
}));

export const CardContentRequestContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${colors.grayLight1}`
}));

export const CardContentRequestHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(10),
  padding: `${rem(2)} ${rem(8)} ${rem(2)} ${rem(12)}`,
  alignItems: 'center',
  alignSelf: 'stretch',
  backgroundColor: colors.grayLight3
}));

export const CardContentRequestMessage = styled('span')(({ theme }) => ({
  fontSize: textSizes.sm,
  fontWeight: weights.medium,
  color: colors.grayDark2,
  textTransform: 'uppercase'
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: rem(5),
  '&.MuiCheckbox-indeterminate': { color: '#4682B4' }
}));

export const RequestContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(9),
  padding: `${rem(8)} ${rem(8)} ${rem(8)} ${rem(12)}`,
  alignItems: 'center',
  alignSelf: 'stretch',
  borderTop: `1px solid ${colors.grayLight1}`
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  padding: rem(5),
  borderRadius: rem(12),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.grayLight2
}));

export const RequestTitle = styled('span')(({ theme }) => ({
  fontSize: textSizes.base,
  fontWeight: weights.medium,
  color: colors.grayDark3,
  flex: 1,
  wordBreak: 'break-word'
}));

export const RequestIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: textSizes.sm1,
  color: colors.grayDark3
}));
