import {
  baseType,
  colors,
  headingSizes,
  textSizes,
  typography,
  weights,
} from '@components/theme/gen2';
import { theme } from '@components/theme/gen2/theme';
import { Avatar, Container, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledBackgroundContainer = styled('div')({
  height: '100%',
  backgroundColor: colors.grayLight2,
  paddingTop: rem(32),
});

export const StyledContainer = styled(Container)({
  background: colors.white,
  padding: 0,
  borderRadius: rem(4),
});

export const ProfileContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px 30px',
  borderRadius: '4px 4px 0px 0px',
  borderBottom: '1px solid rgba(217, 222, 228, 1)',
  backgroundColor: colors.grayLight3,
  gap: '10px',
});

export const ProfileAvatarStyled = styled(Avatar)({
  width: '100px',
  height: '100px',
  border: '1px solid rgba(113, 115, 122, 1)',
  backgroundColor: colors.white,
  color: colors.grayDark2,
});

export const StyledUserCreateForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(40),
  padding: `${rem(40)} ${rem(40)} ${rem(20)} ${rem(40)}`,
  borderRadius: '0px 0px 10px 10px',
  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '& > *': {
      gridColumn: '1 / -1',
    },

    '& > .firstName': {
      gridColumn: '1 / 2',
    },

    '& > .lastName': {
      gridColumn: '2 / -1',
    },
  },
});

export const StyledFormTitle = styled('h6')({
  fontSize: headingSizes.h6,
  margin: 0,
  fontWeight: weights.semiBold,
  fontFamily: baseType,
  color: colors.grayDark3,
});

export const StyledButtonContainer = styled('div')<{
  isShow?: boolean | undefined;
}>(({ isShow = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: isShow ? 'space-between' : 'flex-end',
  gap: '10px',
}));

export const ErrorMessage = styled('span')({
  color: colors.danger,
  fontSize: rem(textSizes.base),
  fontFamily: typography.fontFamily,
  lineHeight: 1.66,
  marginTop: rem(3),
});
