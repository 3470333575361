export interface Breadcrumbs {
  title: string;
  path: string;
}

export interface CustomHeader {
  breadcrumbs?: Breadcrumbs[];
  headerTitle: string;
  subTitle?: string;
}

export const createInviteHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Create Invites',
      path: '#',
    },
  ],
  headerTitle: 'Create Invite',
};

export const editInviteHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Edit Invites',
      path: '#',
    },
  ],
  headerTitle: 'Edit Invite',
};

export const manageInviteHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Manage Invites',
      path: '#',
    },
  ],
  headerTitle: 'Manage Invites',
  subTitle: '| keep track of all your invites',
};

export const draftListingHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Draft Invites',
      path: '#',
    },
  ],
  headerTitle: 'Draft Invites',
  subTitle: '| View, edit and send draft invites',
};

export const archivedListingHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Archived Invites',
      path: '#',
    },
  ],
  headerTitle: 'Archived Invites',
  subTitle: '| Keep a record of all closed Invites',
};

export const collationHistoryHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Collation History',
      path: '/collation-history',
    },
    {
      title: 'Collation-History',
      path: '#',
    },
  ],
  headerTitle: 'Collation History',
  subTitle: '| view all your collation file status',
};

export const integrationsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Integrations',
      path: '/integrations',
    },
    {
      title: 'Integrations',
      path: '#',
    },
  ],
  headerTitle: 'Integrations',
};

export const teamMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team Members',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'Team Members',
  subTitle: '| manage members of your team',
};

export const viewTeamMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team Members',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'View Team Members',
  subTitle: '| view information related to this member of your team',
};

export const createMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team Members',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'New Team Member',
  subTitle: '| manage members of your team',
};

export const updateMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team Members',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'Edit Team Member',
  subTitle: '| manage this member of your team',
};

export const teamsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Teams',
      path: '/teams',
    },
    {
      title: 'Teams',
      path: '#',
    },
  ],
  headerTitle: 'Teams',
  subTitle: '| create and manage teams',
};

export const createTeamsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Teams',
      path: '/teams',
    },
    {
      title: 'Create Teams',
      path: '#',
    },
  ],
  headerTitle: 'New Team',
  subTitle: '| enhance collaboration by grouping members together',
};

export const viewTeamsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Teams',
      path: '/teams',
    },
    {
      title: 'View Team',
      path: '#',
    },
  ],
  headerTitle: 'View Team',
  subTitle: '| view information related to this team',
};

export const editTeamsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Teams',
      path: '/teams',
    },
    {
      title: 'Edit Team',
      path: '#',
    },
  ],
  headerTitle: 'Edit Team',
  subTitle: '| manage team and members',
};

export const dashBoardHeader: CustomHeader = {
  headerTitle: 'Dashboard',
};

export const brandingHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Branding',
      path: '#',
    },
  ],
  headerTitle: 'Branding',
  subTitle: '| customise the client portal with your own logo and colours',
};

export const templatesHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Templates',
      path: '#',
    },
  ],
  headerTitle: 'Templates',
  subTitle: '| Manage your templates',
};

export const fileOptionHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Preferences',
      path: '#',
    },
  ],
  headerTitle: 'Preferences',
};

export const accountHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Settings',
      path: '#',
    },
  ],
  headerTitle: 'Account Settings',
};

export const emptyHeader: CustomHeader = {
  headerTitle: '',
};

export const contactsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contacts',
      path: '/contacts',
    },
    {
      title: 'Contacts',
      path: '#',
    },
  ],
  headerTitle: 'Contacts',
  subTitle: '| manage contacts',
};

export const createContactsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contacts',
      path: '/contacts',
    },
    {
      title: 'Edit Contacts',
      path: '#',
    },
  ],
  headerTitle: 'New Contact',
  subTitle: '| create a new contact',
};

export const viewContactHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contacts',
      path: '/contacts',
    },
    {
      title: 'View Contact',
      path: '#',
    },
  ],
  headerTitle: 'View Contact',
  subTitle: '| view information related to this contact',
};

export const editContactHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contacts',
      path: '/contacts',
    },
    {
      title: 'Edit Contact',
      path: '#',
    },
  ],
  headerTitle: 'Edit Contact',
  subTitle: '| edit the details of a contact',
};

export const createTemplateHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Templates',
      path: '/templates',
    },
    {
      title: 'Create Template',
      path: '#',
    },
  ],
  headerTitle: 'Create New Template',
};

export const viewTemplateHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Templates',
      path: '/templates',
    },
    {
      title: 'View Template',
      path: '#',
    },
  ],
  headerTitle: 'View Template',
};

export const contactGroupsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contact Groups',
      path: '/contact-groups',
    },
    {
      title: 'Contact Groups',
      path: '#',
    },
  ],
  headerTitle: 'Contact Groups',
  subTitle: '| create and manage groupings of contacts',
};

export const createContactGroupsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contact Groups',
      path: '/contact-groups',
    },
    {
      title: 'Create Contact Groups',
      path: '#',
    },
  ],
  headerTitle: 'New Contact Group',
  subTitle: '| create and manage groupings for contacts',
};

export const viewContactGroupsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contact Groups',
      path: '/contact-groups',
    },
    {
      title: 'View Contact Group',
      path: '#',
    },
  ],
  headerTitle: 'View Contact Group',
  subTitle: '| view information related to this contact group',
};

export const editContactGroupsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Contact Groups',
      path: '/contact-groups',
    },
    {
      title: 'Edit Contact Groups',
      path: '#',
    },
  ],
  headerTitle: 'Edit Contact Group',
  subTitle: '| manage groupings for contacts',
};
