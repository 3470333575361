import { config } from '@gen2/config';
import { SearchCondition } from '@gen2/types/search-condition';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export const getContactGroupList = (
  search: SearchCondition,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/contact-groups`,
    params: {
      ...search,
    },
    method: 'get',
  });
};
