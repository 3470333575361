import {
  useDisableTwoFactorAuthMutation,
  useEnableTwoFactorAuthMutation,
} from '@gen2/api/user-settings/hooks';
import { useAuth } from '@gen2/hooks';
import { Chip } from '@mui/material';
import { Loader } from '@nx-fe/components';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangePasswordModal from './change-password-modal/change-password-modal';
import MfaModal from './mfa-modal/mfa-modal';
import {
  MfaStyledBox,
  SecurityBlockStyledBox,
  SecuritySettingsContainerStyledBox,
  SettingsStyledBox,
  StyledButton,
  SubTitle,
  Title,
} from './security-settings.styled';

const SecuritySettings = () => {
  const { t } = useTranslation('userSettings');
  const { user, initialize } = useAuth();

  const { mutate: enableMutate } = useEnableTwoFactorAuthMutation();
  const { mutate: disableMutate } = useDisableTwoFactorAuthMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [openMfaModal, setOpenMfaModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(
    user?.mfa_enabled ?? false,
  );

  useEffect(() => {
    setMfaEnabled(user?.mfa_enabled ?? false);
  }, [user]);

  const mfaActionHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (mfaEnabled) {
      disableMutate('', {
        onSuccess: () => {
          initialize();
          setMfaEnabled(false);
          setIsLoading(false);
        },
      });
    } else {
      enableMutate([], {
        onSuccess: () => {
          initialize();
          setMfaEnabled(true);
          setOpenMfaModal(true);
          setIsLoading(false);
          setOpenChangePasswordModal(false);
        },
      });
    }
  };

  const changePasswordActionHandler: MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();

    setOpenChangePasswordModal(true);
  };

  return (
    <>
      <MfaModal
        open={openMfaModal}
        onClose={() => {
          setOpenMfaModal(false);
        }}
      />

      <ChangePasswordModal
        open={openChangePasswordModal}
        onClose={() => {
          setOpenChangePasswordModal(false);
        }}
      />

      <SecuritySettingsContainerStyledBox>
        <SecurityBlockStyledBox>
          <SettingsStyledBox>
            <Title>{t('securitySettings.title')}</Title>
            <StyledButton
              variant="contained"
              color="secondary"
              data-cy="change-password-button"
              onClick={(e) => {
                changePasswordActionHandler(e);
              }}
            >
              {t('securitySettings.changePassword.title')}
            </StyledButton>
          </SettingsStyledBox>

          <SettingsStyledBox>
            <MfaStyledBox>
              <Title>{t('securitySettings.twoFactorAuth.title')}</Title>
              <Chip
                label={
                  mfaEnabled
                    ? t('securitySettings.twoFactorAuth.enabled')
                    : t('securitySettings.twoFactorAuth.disabled')
                }
                color={mfaEnabled ? 'green' : 'red'}
                variant="outlined"
              />
            </MfaStyledBox>

            <SubTitle>
              {t('securitySettings.twoFactorAuth.description')}
            </SubTitle>
            <StyledButton
              variant="contained"
              color="secondary"
              data-cy="mfa-button"
              onClick={(e) => {
                mfaActionHandler(e);
              }}
              isloading={isLoading.toString()}
            >
              {isLoading ? (
                <Loader
                  props={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 'inherit',
                  }}
                />
              ) : mfaEnabled ? (
                t('securitySettings.twoFactorAuth.disable')
              ) : (
                t('securitySettings.twoFactorAuth.enable')
              )}
            </StyledButton>
          </SettingsStyledBox>
        </SecurityBlockStyledBox>
      </SecuritySettingsContainerStyledBox>
    </>
  );
};

export default SecuritySettings;
