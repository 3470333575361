import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTemplateForm } from '../hooks';
import { MAX_NAME_LENGTH, MAX_SUBJECT_LENGTH } from '../schema';
import { useTemplateFormStore } from '../store';
import { TTemplateForm } from '../template-form';
import { TemplateReminder } from '../template-reminder/template-reminder';
import {
  Container,
  FieldCounter,
  ReminderButton,
  SubmitContainer,
  TemplateDetails,
  TemplateFieldLabel,
  TemplateFieldLine,
  TemplateHeadSection,
  TemplateHeadTextField,
  TemplateHeadTitle,
} from './template-head.styled';

export const TemplateHead = () => {
  const { t } = useTranslation('templates');
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<TTemplateForm>();
  const { openReminder, closeReminder } = useTemplateFormStore();
  const { onSave, onActivate } = useTemplateForm();

  const wName = watch('name');
  const wSubject = watch('subject');

  const handleOpenReminder: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    openReminder(e.target as HTMLElement);
  };

  useEffect(() => {
    return () => {
      closeReminder();
    };
  }, [closeReminder]);

  return (
    <Container>
      <TemplateDetails>
        <TemplateHeadSection>
          <TemplateHeadTitle>{t('form.title_1')}</TemplateHeadTitle>
          <TemplateFieldLine>
            <TemplateFieldLabel>{t('form.name.label')}:</TemplateFieldLabel>
            <TemplateHeadTextField
              placeholder={t('form.name.placeholder') || ''}
              variant="filled"
              hiddenLabel
              fullWidth
              size="small"
              {...register('name')}
              onBlur={onSave}
              error={Boolean(errors.name)}
              helperText={
                <span data-cy="name-error">
                  {errors.name?.message && t(errors.name.message)}
                </span>
              }
            />
            <FieldCounter>
              {t('form.counter', {
                count: wName.length || 0,
                limit: MAX_NAME_LENGTH,
              })}
            </FieldCounter>
          </TemplateFieldLine>
        </TemplateHeadSection>
        <SubmitContainer>
          <Button onClick={onActivate} color="primary" variant="contained">
            {t('form.submit_btn')}
          </Button>
          <p>{t('form.submit_text')}</p>
        </SubmitContainer>
      </TemplateDetails>
      <TemplateHeadSection>
        <TemplateHeadTitle>{t('form.title_2')}</TemplateHeadTitle>
        <TemplateFieldLine>
          <TemplateFieldLabel>
            {t('form.subject.label')}:
            <FontAwesomeIcon icon={solid('info-circle')} />
          </TemplateFieldLabel>
          <TemplateHeadTextField
            placeholder={t('form.subject.placeholder') || ''}
            variant="filled"
            hiddenLabel
            fullWidth
            size="small"
            {...register('subject')}
            onBlur={onSave}
            error={Boolean(errors.subject)}
            helperText={
              <span data-cy="subject-error">
                {errors.subject?.message && t(errors.subject.message)}
              </span>
            }
          />
          <FieldCounter>
            {t('form.counter', {
              count: wSubject?.length || 0,
              limit: MAX_SUBJECT_LENGTH,
            })}
          </FieldCounter>
          <ReminderButton
            color="tertiary"
            variant="outlined"
            size="small"
            onClick={handleOpenReminder}
          >
            {t('form.reminder_btn')}
            <FontAwesomeIcon icon={solid('caret-down')} />
          </ReminderButton>
        </TemplateFieldLine>
      </TemplateHeadSection>
      <TemplateReminder />
    </Container>
  );
};
