import * as Yup from 'yup';
export const contactSchema = Yup.object().shape({
  email: Yup.string()
    .email('email.invalid')
    .required('email.required')
    .max(150, 'email.max'),
  first_name: Yup.string()
    .required('firstName.required')
    .max(50, 'firstName.max'),
  last_name: Yup.string().required('lastName.required').max(50, 'lastName.max'),
  country_iso2_code: Yup.string(),
  company: Yup.string().max(100, 'company.max'),
  position: Yup.string().max(100, 'position.max'),
  // number should have minimum of 10 and maximum of 20 characters
  // should be a string but only accept numbers
  // should be optional
  number: Yup.string().test({
    name: 'mobile-validation',
    message: 'number.invalid',
    test: function (this, value) {
      if (!value) return true; // not required

      const pattern = new RegExp(/(^$)|^[0-9]{6,20}$/, 'g');

      return !!value.match(pattern) && value.length >= 6;
    },
  }),
});
