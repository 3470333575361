import SearchBar from '@components/lib/searchbar/searchbar';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import { InvitesKeys } from '@gen2/api/invites/hooks';
import { TTemplate } from '@gen2/api/templates/api';
import {
  useSelectTemplateMutation,
  useTemplatesQuery,
} from '@gen2/api/templates/hooks';
import { FeatureFlags } from '@gen2/app/components/feature-flags/feature-flags';
import { Loading } from '@gen2/app/invite-listing/invite-listing-item/loading';
import {
  EmptyTemplates,
  TemplateCard,
  TemplateCardBadge,
  TemplateCardContent,
  TemplateCardDate,
  TemplateCardHeader,
  TemplateCardName,
} from '@gen2/app/templates/templates.styled';
import { queryClient } from '@gen2/config';
import { useAuth, useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import { useSendInviteStore } from '../store';
import { MergeTemplatesModal } from './merge-templates-modal/merge-templates-modal';
import { useMergeTemplateModalStore } from './merge-templates-modal/store';
import { TemplateIcon } from './template-icon';
import {
  HeaderTitle,
  SearchAndActionContainer,
  SearchContainer,
  TemplateCards,
  TemplateContent,
  TemplatePaginationArrows,
  TemplatesHeader,
  TemplatesHeaderActions,
  TemplatesHeaderTop,
  TemplatesMobileClose,
  TemplatesModal,
} from './templates.styled';

export const Templates = () => {
  const theme = useTheme();
  const mediaMatch = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletMatch = useMediaQuery(theme.breakpoints.down('md'));
  const { featureFlags } = useAuth();
  const [selectedTemplates, setSelectedTemplates] = useState<TTemplate[]>([]);
  const {
    invite,
    isTemplatesOpen,
    setIsTemplatesOpen,
    reset,
    setContextInviteIdForNewInvite,
  } = useSendInviteStore();

  const { showModal } = useMergeTemplateModalStore();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useTemplatesQuery(
    {
      page,
      per_page: 25,
      'filter[name]': debouncedQuery,
    },
    {
      enabled: isTemplatesOpen,
    },
  );
  const toast = useToast();
  const {
    mutateAsync: selectTemplateMutation,
    isLoading: isInviteRequestsUpdating,
  } = useSelectTemplateMutation();
  const { t } = useTranslation('templates');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleClose = () => {
    setIsTemplatesOpen(false);
  };

  const nextPage = () => {
    if (!data?.templates?.length) return;

    setPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (page <= 1) return;

    setPage((prev) => prev - 1);
  };

  const onSelect = (template: TTemplate) => {
    setSelectedTemplates((prev) => {
      if (!featureFlags['merge_templates']) {
        return [template];
      }

      if (prev.find((t) => t.id === template.id)) {
        return prev.filter((t) => t.id !== template.id);
      }

      if (prev.length >= 3) {
        return prev;
      }

      return [...prev, template];
    });
  };

  // it's only new if it was created today
  const isStillNew = (createdAt: Date | string) => {
    return dayjs(createdAt).isSame(dayjs(), 'day');
  };

  const onConfirm = async () => {
    if (!selectedTemplates) return;

    handleCloseConfirmation();

    // reset the form
    reset();

    try {
      await selectTemplateMutation({
        templateId: selectedTemplates[0].id,
        inviteId: invite?.id,
      });

      await queryClient.invalidateQueries([
        InviteRequestsKeys.getInviteRequests,
      ]);
      await queryClient.invalidateQueries([InvitesKeys.getInvite]);

      toast.show({
        text: t('afterChange'),
        variant: 'success',
      });
    } catch (err) {
      toast.show({
        text: t('failedToChange'),
        variant: 'error',
      });
    } finally {
      if (invite?.id) {
        setContextInviteIdForNewInvite(invite.id);
      }

      handleClose();
    }
  };

  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const handleMergeTemplates = async () => {
    const { readyToSend } = await showModal({
      templates: selectedTemplates,
    });

    if (readyToSend) {
      handleClose();
    }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (page !== 1) {
      setPage(1);
    }

    setQuery(e.target.value);
  };

  return (
    <>
      <TemplatesModal
        open={isTemplatesOpen}
        onClose={handleClose}
        aria-labelledby="templates"
        aria-describedby="templates-title"
        fullScreen={mediaMatch}
        data-cy="templates-modal"
      >
        {!mediaMatch && (
          <ModalCloseButton
            data-cy="templates-modal-close"
            aria-label="close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={regular('close')} />
          </ModalCloseButton>
        )}

        <TemplatesHeader>
          <TemplatesHeaderTop>
            <HeaderTitle id="templates-title">Templates</HeaderTitle>
            {mediaMatch && (
              <TemplatesMobileClose
                data-cy="templates-modal-mobile-close"
                aria-label="close"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={regular('x')} />
              </TemplatesMobileClose>
            )}
          </TemplatesHeaderTop>

          {!tabletMatch && (
            <SearchContainer>
              <SearchBar
                id="search-templates"
                placeholder="Search templates"
                onChange={handleSearch}
                onReset={() => setQuery('')}
                value={query}
                data-cy="search-templates"
              />
            </SearchContainer>
          )}

          <SearchAndActionContainer>
            {tabletMatch && (
              <SearchContainer>
                <SearchBar
                  id="search-templates"
                  placeholder="Search templates"
                  onChange={handleSearch}
                  onReset={() => setQuery('')}
                  value={query}
                  data-cy="search-templates"
                />
              </SearchContainer>
            )}

            <TemplatesHeaderActions>
              <LoadingButton
                onClick={handleOpenConfirmation}
                color="primary"
                variant="contained"
                disabled={selectedTemplates.length !== 1}
                data-cy="load-selected-template-btn"
                loading={isInviteRequestsUpdating}
              >
                Load Template
              </LoadingButton>
              <FeatureFlags restriction="merge_templates">
                <LoadingButton
                  onClick={handleMergeTemplates}
                  color="secondary"
                  variant="contained"
                  disabled={selectedTemplates.length < 2}
                  data-cy="load-selected-template-btn"
                  loading={isInviteRequestsUpdating}
                >
                  Merge Templates
                </LoadingButton>
              </FeatureFlags>
            </TemplatesHeaderActions>
          </SearchAndActionContainer>

        </TemplatesHeader>
        <TemplateContent dividers $loading={isLoading}>
          <TemplatePaginationArrows onClick={prevPage} disabled={isLoading}>
            <FontAwesomeIcon icon={regular('chevron-left')} />
          </TemplatePaginationArrows>
          <TemplatePaginationArrows
            onClick={nextPage}
            $right
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={regular('chevron-right')} />
          </TemplatePaginationArrows>
          {isLoading ? (
            <Loading />
          ) : (
            <TemplateCards>
              {data?.templates?.length ? (
                data.templates.map((template) => (
                  <TemplateCard
                    $active={selectedTemplates.filter((t) => t.id === template.id).length > 0}
                    onClick={() => onSelect(template)}
                    key={template.id}
                    data-cy={`template-card-${template.id}`}
                  >
                    <TemplateCardHeader>
                      <TemplateCardDate>
                        {dayjs(template.created_at).format('MMM D, YYYY')}
                      </TemplateCardDate>
                    </TemplateCardHeader>
                    <TemplateCardContent>
                      <TemplateIcon />
                      <TemplateCardName>{template.name}</TemplateCardName>
                    </TemplateCardContent>
                    {isStillNew(template.created_at) && (
                      <TemplateCardBadge>
                        <span>New</span>
                      </TemplateCardBadge>
                    )}
                  </TemplateCard>
                ))
              ) : (
                <EmptyTemplates>{t('notFound')}</EmptyTemplates>
              )}
            </TemplateCards>
          )}
        </TemplateContent>
        {mediaMatch && (
          <DialogActions>
            <Button
              onClick={handleOpenConfirmation}
              color="primary"
              variant="contained"
              disabled={selectedTemplates.length === 0}
              data-cy="load-selected-template-btn"
            >
              Load Selected Template
            </Button>
          </DialogActions>
        )}

        {/* confirmation */}
        <Dialog data-cy="confirmation-modal" open={isConfirmationOpen}>
          <ModalCloseButton aria-label="close" onClick={handleCloseConfirmation}>
            <FontAwesomeIcon icon={regular('close')} />
          </ModalCloseButton>
          <DialogTitle id="confirmation-dialog-title">
            {t('confirm.title')}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="confirmation-dialog-description">
              {t('confirm.desc')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirmation}
              color="tertiary"
              variant="outlined"
              data-cy="cancel-change-template-btn"
            >
              Cancel
            </Button>
            <Button
              data-cy="confirm-change-template-btn"
              onClick={onConfirm}
              color="primary"
              variant="contained"
            >
              Yes, Load Template
            </Button>
          </DialogActions>
        </Dialog>
      </TemplatesModal>

      <FeatureFlags restriction="merge_templates">
        <MergeTemplatesModal />
      </FeatureFlags>
    </>

  );
};
