import { colors } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';
export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      alignItems: 'flex-end',
    },
    flexContainer: {
      alignItems: 'flex-end',
    },
    indicator: {
      display: 'none',
    },
  },
};

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      borderTop: `${rem(1)} solid ${colors.grayLight1}`,
      borderLeft: `${rem(1)} solid ${colors.grayLight1}`,
      borderRight: `${rem(1)} solid ${colors.grayLight1}`,
      height: rem(40),
      minHeight: 'unset',
      color: colors.grayDark3,
      // first child
      '&:first-child': {
        borderTopLeftRadius: rem(10),
        borderLeft: `${rem(1)} solid ${colors.grayLight1}`,
      },

      // last child
      '&:last-child': {
        borderTopRightRadius: rem(10),
      },

      // selected
      '&.Mui-selected': {
        borderTopLeftRadius: rem(10),
        borderTopRightRadius: rem(10),
        borderTop: `${rem(3)} solid ${colors.primary}`,
        backgroundColor: colors.white,
        borderColor: colors.primary,
        color: colors.grayDark3,
        height: rem(45),
        borderBottom: 'none',
      },
    },
  },
};
