import { useContactGroupsQuery } from '@gen2/api/invite-contact-groups/hooks';
import { useContactsQuery } from '@gen2/api/invite-contacts/hooks';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useCallback, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useBasicTabsControl } from '../base-popup-with-tabs/useBasicTabsControl';

export const useContactPopup = () => {
  const { tabValue, handleTabChange } = useBasicTabsControl(1);

  const [groupQuery, setGroupQuery] = useState<string>('');
  const [debouncedGroupQuery] = useDebounceValue(groupQuery, 500);

  const [contactQuery, setContactQuery] = useState<string>('');
  const [debouncedContactQuery] = useDebounceValue(contactQuery, 500);
  const { setPrePopulatedEmail } = useSendInviteStore();

  const { data: data_contacts, isLoading: isContactLoading } = useContactsQuery(
    {
      'filter[search]': debouncedContactQuery,
      'sort[column]': 'created_at',
      'sort[order]': 'desc',
    },
    {
      suspense: false,
      enabled:
        debouncedContactQuery.length >= 2 ||
        (!debouncedContactQuery && tabValue === 1),
    },
  );

  const { data: data_groups, isLoading: isGroupLoading } =
    useContactGroupsQuery(
      {
        'sort[column]': 'name',
        'sort[order]': 'desc',
        'filter[search]': debouncedGroupQuery,
        per_page: 10,
        page: 1,
      },
      {
        suspense: false,
        enabled:
          debouncedGroupQuery.length >= 2 ||
          (!debouncedGroupQuery && tabValue === 2),
      },
    );

  const handleSearchContacts = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!e.target.value) {
      setPrePopulatedEmail('');
    }
    setContactQuery(e.target.value);
  };

  const handleResetContacts = useCallback(() => {
    setContactQuery('');
    setPrePopulatedEmail('');

  }, [setPrePopulatedEmail]);

  const handleSearchGroups = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setGroupQuery(e.target.value);
  };

  const handleResetGroups = useCallback(() => {
    setGroupQuery('');
  }, []);

  return {
    data_contacts,
    data_groups,
    isContactLoading,
    isGroupLoading,
    groupQuery,
    contactQuery,
    debouncedContactQuery,
    tabValue,
    handleSearchContacts,
    handleResetContacts,
    handleSearchGroups,
    handleResetGroups,
    handleTabChange,
  };
};
