import { weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledRequests = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  marginTop: rem(50),
});

export const StyledRequestsHead = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledTitle = styled('span')({
  fontSize: rem(15),
  fontWeight: weights.semiBold,
});

export const StyledButtonGroup = styled('div')({
  display: 'flex',
  gap: rem(10),
});

export const StyledContainer = styled('div')<{ $opaque: boolean }>(
  ({ $opaque }) => ({
    ...($opaque && {
      opacity: 0.5,
      pointerEvents: 'none',
      userSelect: 'none',
    }),
  }),
);

export const StyledCreateContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: rem(10),
  marginTop: rem(20),
});
