import {
  StyledFileUpload,
  StyledFileUploadActions,
  StyledFileUploadCancel,
  StyledFileUploadFileSize,
  StyledFileUploadIconButton,
  StyledFileUploadIconButtonCancel,
  StyledFileUploadIconButtonCancelElement,
  StyledFileUploadInfo,
  StyledFileUploadMore,
  StyledIconButton,
} from '@components/lib/file-upload/file-upload.styles';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetFileUrlQuery } from '@gen2/api/files/hooks';
import {
  LiveFormType,
  useSendInviteStore,
} from '@gen2/app/invites/send-invites/store';
import { useRouter } from '@gen2/hooks';
import {
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useUnmount } from 'usehooks-ts';
import { CreateFormModal } from './create-form-modal';
import { PreviewWebViewerModal } from './preview-web-viewer-modal';
import { TemplateCreateFormModal } from './template-create-form-modal';
import { TemplateWebViewerModal } from './template-web-viewer-modal';
import { WebViewerModal } from './web-viewer-modal';

/* eslint-disable-next-line */
export interface LiveFormControlsProps {
  key: string;
  progress: number;
  children?: React.ReactNode;
  fileId: string;
  fileSize: string;
  fileName: string;
  isUploading?: boolean;
  icon?: React.ReactNode;
  onDelete?: () => void;
}

export interface FileUploadActionProps {
  children?: React.ReactNode;
  tablet?: boolean;
}

export const FileUploadActions = ({
  children,
  tablet,
}: FileUploadActionProps) => {
  return (
    <StyledFileUploadActions $tablet={tablet}>
      {children}
    </StyledFileUploadActions>
  );
};

const noop = () => {
  return;
};

export const LiveFormControls = ({
  children,
  fileName,
  fileId,
  progress,
  fileSize,
  isUploading = false,
  icon,
  onDelete,
  ...rest
}: LiveFormControlsProps) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const store = useSendInviteStore();
  const router = useRouter();
  const [isPreviewViewerOpen, setIsPreviewViewerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isCreateFormModalOpen, setIsCreateFormModalOpen] = useState(false);
  const [isWebViewerModalOpen, setIsWebViewerModalOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [pass, setPass] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const { data } = useGetFileUrlQuery({
    fileId,
    open: isCreateFormModalOpen,
  });

  useEffect(() => {
    // when data has value and will close the modal and switch to the next step
    // pass - The control is only executed when make fillable is clicked， It is related to the mounting method of the entire component
    if (data && pass) {
      setFileUrl(data);
      // setIsLoading(false);
      // setIsCreateFormModalOpen(false);
      // setIsWebViewerModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  //don't update this!!

  useUnmount(() => {
    // Cleanup logic here
    setIsCreateFormModalOpen(false);
    setIsWebViewerModalOpen(false);
    // setIsLoading(true);
    setPass(false);
  });

  const isInTemplates = router.pathname.includes('templates');

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: () => void) => () => {
    setAnchorEl(null);
    event();
  };

  const preview = (event: () => void) => () => {
    setIsPreviewViewerOpen(true);
    event();
  };

  const makeFillableForm = (event: () => void) => () => {
    setIsCreateFormModalOpen(true);

    setPass(true);
    event();
  };

  const handleOnSelected = (liveFormType: LiveFormType) => {
    store.setLiveFormType(liveFormType);
    handleCreateFormModalClose();
    setIsWebViewerModalOpen(true);
  };

  const handleCreateFormModalClose = () => {
    setIsCreateFormModalOpen(false);
  };

  return (
    <>
      <StyledFileUpload
        $progress={progress}
        $uploading={isUploading}
        $tablet={tablet}
        {...rest}
      >
        <StyledFileUploadInfo>
          {icon}
          <span>{fileName}</span>
          <StyledFileUploadFileSize>{fileSize}</StyledFileUploadFileSize>
        </StyledFileUploadInfo>
        {tablet ? (
          <FileUploadActions tablet={tablet}>
            <StyledIconButton
              variant="outlined"
              color="tertiary"
              onClick={makeFillableForm(noop)}
            >
              Edit Form Fields
              {/* <FontAwesomeIcon icon={regular('pencil')} /> */}
            </StyledIconButton>
            <Tooltip title="View">
              <StyledFileUploadIconButton
                onClick={preview(noop)}
                color="tonalLight"
              >
                <FontAwesomeIcon icon={regular('eye')} />
              </StyledFileUploadIconButton>
            </Tooltip>
            {isUploading ? (
              <StyledFileUploadIconButtonCancel $progress={progress}>
                <StyledFileUploadIconButtonCancelElement>
                  <FontAwesomeIcon icon={regular('close')} />
                </StyledFileUploadIconButtonCancelElement>
              </StyledFileUploadIconButtonCancel>
            ) : (
              <Tooltip title="Delete">
                <StyledFileUploadIconButton
                  data-cy="delete"
                  onClick={onDelete}
                  color="tonalLight"
                >
                  <FontAwesomeIcon icon={regular('trash-can')} />
                </StyledFileUploadIconButton>
              </Tooltip>
            )}
          </FileUploadActions>
        ) : (
          <StyledFileUploadActions $tablet={tablet}>
            {isUploading ? (
              <StyledFileUploadCancel>
                <FontAwesomeIcon size="xl" icon={regular('close')} />
              </StyledFileUploadCancel>
            ) : (
              <StyledFileUploadMore
                id="file-upload-menu-btn"
                data-testid="file-upload-menu-btn"
                aria-controls={open ? 'file-upload-menu' : ''}
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <FontAwesomeIcon
                  size="xl"
                  icon={regular('ellipsis-vertical')}
                />
              </StyledFileUploadMore>
            )}
          </StyledFileUploadActions>
        )}
        {!tablet && (
          <Menu
            id="file-upload-menu"
            data-testid="file-upload-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose(noop)}
            MenuListProps={{
              'aria-labelledby': 'file-upload-menu-btn',
            }}
          >
            <MenuItem onClick={handleMenuClose(onDelete ?? noop)}>
              Delete
            </MenuItem>
          </Menu>
        )}
        {children}
      </StyledFileUpload>

      {isPreviewViewerOpen && (
        <PreviewWebViewerModal
          isOpen={isPreviewViewerOpen}
          fileId={fileId}
          fileName={fileName}
          onClose={() => setIsPreviewViewerOpen(false)}
        />
      )}

      {isWebViewerModalOpen && (
        !isInTemplates
          ? (
            <WebViewerModal
              fileId={fileId}
              fileUrl={fileUrl}
              fileName={fileName}
              open={isWebViewerModalOpen}
              onClose={() => setIsWebViewerModalOpen(false)}
            />
          )
          : (
            <TemplateWebViewerModal
              fileId={fileId}
              fileUrl={fileUrl}
              fileName={fileName}
              type={store.liveFormType}
              open={isWebViewerModalOpen}
              onClose={() => setIsWebViewerModalOpen(false)}
            />
          )
      )}

      {isCreateFormModalOpen && (
        !isInTemplates
          ? (
            <CreateFormModal
              fileId={fileId}
              open={isCreateFormModalOpen}
              onClose={handleCreateFormModalClose}
              onSelected={handleOnSelected}
              fileUrl={fileUrl}
            />
          )
          : (
            <TemplateCreateFormModal
              fileId={fileId}
              open={isCreateFormModalOpen}
              onClose={handleCreateFormModalClose}
              onSelected={handleOnSelected}
              fileUrl={fileUrl}
            />
          )
      )}
    </>
  );
};

export default LiveFormControls;
