import { TUpdateTemplate } from '@gen2/api/templates/api';
import { TTemplateForm } from './template-form';

export const editTemplatePayload = (
  id: string,
  values: TTemplateForm,
): TUpdateTemplate => {
  return {
    templateId: id,
    name: values.name,
    subject: values.subject,
    message: values?.rawMessage,
    meta: values?.meta,
    overdue_reminder: Number(
      values.overdue_reminder === 'custom'
        ? values.customOverdueValue
        : values.overdue_reminder,
    ),
    almost_due_reminder: Number(
      values.almost_due_reminder === 'custom'
        ? values.customAlmostDueValue
        : values.almost_due_reminder,
    ),
    status: values.status === 'active' ? 'active' : 'draft',
  };
};

export const activateTemplatePayload = (
  id: string,
  values: TTemplateForm,
): TUpdateTemplate => {
  return {
    templateId: id,
    name: values.name,
    status: 'active',
  };
};
