import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Container,
  Content,
  Divider,
  Heading,
  Logo,
  StyledUnauthorized,
} from './unauthorized.styled';

export const Unauthorized = () => {
  return (
    <StyledUnauthorized>
      <Container>
        <Logo src="/assets/logo-icon--dark.svg" alt="FileInvite" />
        <Heading>Oops! You don&apos;t have access.</Heading>
        <Divider />
        <Content>
          It looks like you’re trying to access a page that requires special
          permissions. If you believe you need access to this area, please reach
          out to an administrator for assistance.
        </Content>
        <Link to="/invite-listing">
          <Button color="primary" variant="contained">
            Back to FileInvite
          </Button>
        </Link>
      </Container>
    </StyledUnauthorized>
  );
};
