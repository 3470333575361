import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  TextField,
  FormControl,
  FormHelperText,
  Box,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps,
} from '@mui/material';
import {
  getCountry,
  ICountryCode,
  suggestedCountries,
  unSuggestCountry,
} from './country';
import {
  menuProps,
  Mobile,
  StyledItemBox,
  TooltipContainer,
  LabelContainer,
  StyledBox,
  StyledTooltip,
} from './form-phone.styles';

interface FormPhone {
  mobNumber?: string;
  error?: boolean;
  showTooltip?: boolean;
  errorMessage?: string | React.ReactNode | null;
  cypressId?: string;
  required?: boolean;
  label?: string;
  title?: string;
  codeFieldProps: SelectProps;
  phoneFieldProps: TextFieldProps;
}

const renderValue = (countryCode: string) => {
  const country = getCountry(countryCode) as ICountryCode;
  return (
    <StyledBox>
      <LabelContainer>+{country.phone}</LabelContainer>
    </StyledBox>
  );
};

const renderMenuItem = (country: ICountryCode) => {
  return (
    <MenuItem key={country.code} value={country.code}>
      <StyledItemBox>
        <LabelContainer>{country.label}</LabelContainer>
        <LabelContainer> +{country.phone}</LabelContainer>
      </StyledItemBox>
    </MenuItem>
  );
};

const FormPhone: React.FC<FormPhone> = ({
  error = false,
  showTooltip = true,
  errorMessage,
  cypressId,
  required = false,
  label = 'Mobile Number',
  title = 'Used for SMS notifications, which have a 98% open rate',
  codeFieldProps,
  phoneFieldProps,
}: FormPhone) => {
  return (
    <Box>
      <Mobile>
        <FormControl error={error}>
          <Select
            color="info"
            id="country-code-selector"
            data-cy="FP-select"
            renderValue={renderValue as (value: unknown) => React.ReactNode}
            inputProps={{
              'data-cy': `FP-select-${cypressId}`,
            }}
            MenuProps={menuProps}
            defaultValue="AU"
            value={codeFieldProps.defaultValue}
            {...codeFieldProps}
          >
            {suggestedCountries.map((country) => renderMenuItem(country))}
            <Divider />
            {unSuggestCountry.map((country) => renderMenuItem(country))}
          </Select>
        </FormControl>
        <TextField
          error={error}
          required={required}
          label={label}
          inputProps={{
            'data-cy': `FP-input-${cypressId}`,
          }}
          type="number"
          fullWidth
          value={phoneFieldProps.value}
          {...phoneFieldProps}
        />
        {showTooltip && (
          <TooltipContainer>
            <StyledTooltip title={title} placement="top">
              <FontAwesomeIcon size="lg" icon={solid('question-circle')} />
            </StyledTooltip>
          </TooltipContainer>
        )}
      </Mobile>
      {error && (
        <FormHelperText data-cy={`FP-error-${cypressId}`} error>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormPhone;
