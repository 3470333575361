import { colors, textSizes } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { TagsLine } from '@nx-fe/components';
import { rem } from 'polished';

interface StyledCaretIconProps {
  open: boolean;
}

export const RequestContactsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: rem(12),
  height: 'auto'
});

export const RequestContactDescription = styled(Box)({
  flexShrink: 0
});

export const StyledSelect = styled(Select)({
  display: 'flex',
  width: '100%',
  height: 'auto',
  background: colors.white,

  '& .MuiSelect-select': {
    padding: rem(8), // Adjust the padding as needed
  },
});

export const StyledSelectOption = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: rem(8),
  padding: rem(8)
});

export const StyledSendTo = styled(TagsLine)({
  padding: 'unset',
  flex: 1,

  '& .MuiChip-filled': {
    height: rem(24),
    padding: `${rem(3)} ${rem(8)}`,
  }
});

export const StyledIconContainer = styled(Box)({
  flexGrow: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledCaretIcon = styled(FontAwesomeIcon)<StyledCaretIconProps>(({ open }) => ({
  flexGrow: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: open ? colors.grayDark2 : colors.grayLight2,
  transform: open ? 'rotate(180deg)' : 'rotate(0)'
}));

export const StyledCheckbox = styled(Checkbox)({
  padding: 'unset',
  fontSize: textSizes.md,
  '&.Mui-checked': {
    color: colors.secondaryDark1
  }
});
