import { TGetTemplate } from '@gen2/api/templates/api';
import { noop } from 'lodash';
import { create } from 'zustand';

export type UpgradeModal = {
  isOpen: boolean;
  title?: string;
  description?: string;
};

export interface ITemplateFormStore {
  reminderAnchorEl: HTMLElement | null;
  template: TGetTemplate | null;
  upgradeModal: UpgradeModal;

  openReminder: (anchorEl: HTMLElement | null) => void;
  closeReminder: () => void;
  setTemplate: (template: TGetTemplate) => void;
  setUpgradeModal: (upgradeModal: UpgradeModal) => void;
}

export const defaultTemplateFormStore: ITemplateFormStore = {
  reminderAnchorEl: null,
  template: null,
  upgradeModal: {
    isOpen: false,
    title: '',
    description: '',
  },

  openReminder: noop,
  closeReminder: noop,
  setTemplate: noop,
  setUpgradeModal: noop,
};

export const useTemplateFormStore = create<ITemplateFormStore>()((set) => ({
  ...defaultTemplateFormStore,

  openReminder: (anchorEl) =>
    set((state) => ({
      ...state,
      reminderAnchorEl: state.reminderAnchorEl === anchorEl ? null : anchorEl,
    })),
  closeReminder: () =>
    set((state) => ({
      ...state,
      reminderAnchorEl: null,
    })),
  setTemplate: (template) => set({ template }),
  setUpgradeModal: (upgradeModal) => set({ upgradeModal }),
}));
