import { colors } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const Container = styled('div')({
  backgroundColor: colors.grayLight2,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const TemplateFormBody = styled('div')({
  width: rem(840),
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: rem(60),
});
