import { useDeleteFilesMutation } from '@gen2/api/files/hooks';
import {
  TemplateKeys,
  useCreateTemplateRequestMutation,
  useDeleteTemplateRequestMutation,
  useGetTemplateRequestsQuery,
} from '@gen2/api/templates/hooks';
import { ActionModal } from '@gen2/app/components/action-modal/action-modal';
import { ConfirmationModal } from '@gen2/app/components/confirmation-modal/confirmation-modal';
import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import { CreateRequest } from '@gen2/app/components/invite-request/create-request/create-request';
import { useFileStore } from '@gen2/app/components/invite-request/hooks/file-store';
import { InviteRequest } from '@gen2/app/components/invite-request/invite-request';
import UpgradeModal from '@gen2/app/components/modals/upgrade-modal';
import {
  defaultRequests,
  TInviteRequestsForm,
} from '@gen2/app/invites/send-invites/requests/requests';
import { inviteRequestsSchema } from '@gen2/app/invites/send-invites/requests/schema';
import { useRequestsStore } from '@gen2/app/invites/send-invites/requests/store';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MAX_TEMPLATE_REQUESTS } from '../schema';
import { useTemplateFormStore } from '../store';
import { RequestList } from './template-requests.styled';

export const TEMPLATE_INITIAL_REQUEST_TITLE = 'New File Upload Request';

export const TemplateRequestsList = () => {
  const { fields: requests } = useFieldArray({
    name: 'requests',
  });
  const { toDeleteRequest, setToDeleteRequest } = useRequestsStore();
  const {
    mutate: deleteTemplateRequestMutation,
    isLoading: isRequestDeleting,
  } = useDeleteTemplateRequestMutation();
  const { setIsConfirmationModalOpen, setConfirmationModalProps } =
    useConfirmationModalStore();
  const toast = useToast();
  const { t } = useTranslation('sendInvite');
  const { templateId } = useParams<{
    templateId: string;
  }>();
  const { file: selectedFile, setFile: setSelectedFile } = useFileStore();
  const { mutate: deleteFilesMutation, isLoading: isFileDeleting } =
    useDeleteFilesMutation();

  const onDelete = useCallback(() => {
    if (toDeleteRequest) {
      // delete template request
      deleteTemplateRequestMutation(
        {
          id: toDeleteRequest.id ?? '',
          templateId: templateId ?? '',
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([
              TemplateKeys.getTemplateRequests,
            ]);

            toast.show({
              text: t('request.delete.success'),
              variant: 'success',
            });

            return null;
          },
          onError: () => {
            toast.show({
              text: t('request.delete.error'),
              variant: 'error',
            });
          },
          onSettled: () => {
            setToDeleteRequest(undefined);
            setIsConfirmationModalOpen(false);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteTemplateRequestMutation,
    toDeleteRequest,
    setIsConfirmationModalOpen,
    toast,
  ]);

  const handleFileDelete = useCallback(() => {
    if (selectedFile) {
      deleteFilesMutation(
        {
          type: 'request',
          typeId: selectedFile.request.id,
          files: [{ id: selectedFile.id }],
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([TemplateKeys.getTemplateRequests]);

            toast.show({
              text: t('file.delete.success'),
              variant: 'success',
            });
          },
          onError: (err: any) => {
            toast.show({
              text: t('file.delete.error'),
              variant: 'error',
            });
          },
          onSettled: () => {
            setSelectedFile(undefined);
            setIsConfirmationModalOpen(false);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, toast]);

  useEffect(() => {
    if (!toDeleteRequest?.title) return;

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: onDelete,
      isLoading: isRequestDeleting,
      title: t('request.delete.title'),
      submitLabel: t('request.delete.buttonLabel'),
      submitColor: 'danger',
      message: t('request.delete.message', {
        requestName: toDeleteRequest.title,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRequestDeleting,
    onDelete,
    toDeleteRequest?.title,
    setConfirmationModalProps,
    setIsConfirmationModalOpen,
  ]);

  useEffect(() => {
    if (!selectedFile) return;

    const isDeleteFileFromFormType = selectedFile.request.type === 'live-form';

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: handleFileDelete,
      isLoading: isFileDeleting,
      title: isDeleteFileFromFormType
        ? t('editSendInvite.file.delete.title')
        : t('file.delete.title'),
      submitLabel: t('file.delete.buttonLabel'),
      submitColor: 'danger',
      message: t('file.delete.message', {
        fileName: selectedFile.original_name,
      }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileDeleting, selectedFile]);

  return (
    <RequestList>
      {requests.map((request, index) => (
        <InviteRequest
          key={request.id}
          id={index}
          container="template"
          hasDelete={requests.length > 1}
          isDeleteDisabled={isRequestDeleting}
        />
      ))}
    </RequestList>
  );
};

export const TemplateRequests = React.memo(() => {
  const methods = useForm<TInviteRequestsForm>({
    defaultValues: defaultRequests,
    resolver: yupResolver(inviteRequestsSchema),
  });
  const { templateId } = useParams<{
    templateId: string;
  }>();
  const { data } = useGetTemplateRequestsQuery(templateId ?? '');
  const { upgradeModal, setUpgradeModal } = useTemplateFormStore();
  const { mutate: createTemplateMutation, isLoading: isCreating } =
    useCreateTemplateRequestMutation();
  const { t } = useTranslation('templates');

  useEffect(() => {
    if (!data || !data?.length) return;

    methods.reset({
      requests: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, data, methods.reset]);

  const onCreate = () => {
    if ((data?.length || 0) >= MAX_TEMPLATE_REQUESTS) {
      setUpgradeModal({
        isOpen: true,
        title: t('form.upgrade.title') || '',
        description: t('form.upgrade.description') || '',
      });
      return;
    }

    createTemplateMutation(
      {
        title: TEMPLATE_INITIAL_REQUEST_TITLE,
        type: 'file-upload',
        templateId: templateId ?? '',
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            TemplateKeys.getTemplateRequests,
          ]);
        },
      },
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <TemplateRequestsList />
        <CreateRequest onClick={onCreate} disabled={isCreating} />
      </FormProvider>
      <UpgradeModal
        open={upgradeModal.isOpen}
        onClose={() => setUpgradeModal({ isOpen: false })}
        title={upgradeModal.title}
      >
        {upgradeModal.description}
      </UpgradeModal>
      <ConfirmationModal />
      <ActionModal />
    </>
  );
});
