import { config } from '@gen2/config';
import { TContact } from '@gen2/types/contact';
import { AxiosPromise } from 'axios';
import { Dayjs } from 'dayjs';
import axios from '../axios.request';

export interface TDraftInviteRequest {
  from_user_id: string;
  subject?: string;
  message?: string;
  due_at?: Dayjs | null | string;
  contacts?: Array<{ id: string }>;
  contact_ids?: Array<string>;
  contact_group_ids?: string[];
  meta?: string;
  overdue_reminder?: string;
  almost_due_reminder?: string;
}

export interface TDraftInviteResponse {
  data: {
    invite: {
      id: string;
      subject: string;
      message: string;
      due_at: string;
      contacts: string[];
    };
  };
  message: string;
}

export const draftInvite = (
  data: TDraftInviteRequest,
): AxiosPromise<TDraftInviteResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export interface TEditDraftRequest {
  id: string;
  from_user_id?: string;
  subject?: string;
  message?: string;
  due_at?: string;
  meta?: string;
  overdue_reminder?: number;
  almost_due_reminder?: number;
}

export interface TEditDraftResponse {
  data: {
    invite: {
      id: string;
      subject: string;
      message: string;
      due_at: string;
    };
  };
  message: string;
}

export const editDraftInvite = (
  data: TEditDraftRequest,
): AxiosPromise<TEditDraftResponse> => {
  const { id, ...payload } = data;

  return axios.request({
    url: `${config.apiVersion.v1}/invites/${id}`,
    data: payload,
    method: 'put',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TAddContactToInviteRequest = {
  inviteId: string;
  contact_ids: Array<string>;
};

export type TAddContactToInviteResponse = {
  data: {
    invite: {
      id: string;
      contacts: TContact[];
    };
  };
};

export const addContactsToInvite = (
  data: TAddContactToInviteRequest,
): AxiosPromise<TAddContactToInviteResponse> => {
  const { inviteId, contact_ids } = data;

  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/contacts`,
    data: { contact_ids },
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TAddContactGroupToInviteRequest = {
  inviteId: string;
  contact_group_ids: string[];
};

export const addContactGroupsToInvite = (
  data: TAddContactGroupToInviteRequest,
): AxiosPromise<TAddContactToInviteResponse> => {
  const { inviteId, contact_group_ids } = data;

  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/contacts`,
    data: { contact_group_ids },
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TDeleteContactFromInviteRequest = {
  inviteId: string;
  contactId: string;
};

export type TDeleteContactFromInviteResponse = {
  data: {
    invite: {
      id: string;
      contacts: TContact[];
    };
  };
  message: string;
};

export const deleteContactFromInvite = (
  data: TDeleteContactFromInviteRequest,
): AxiosPromise<TDeleteContactFromInviteResponse> => {
  const { inviteId, contactId } = data;

  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/contacts/${contactId}`,
    method: 'delete',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TInvite = {
  id: string;
  subject: string;
  message: string;
  due_at: string;
  contacts: TContact[];
  created_at: string;
  meta: string;
  overdue_reminder: string;
  almost_due_reminder: string;
  status: 'draft' | 'sent' | '';
};

export type TGetInviteResponse = {
  data: {
    invite: TInvite;
  };
  message: string;
};

export const getInvite = (id: string): AxiosPromise<TGetInviteResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${id}`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export interface SendInviteReques {
  data?: TDraftInviteRequest;
  id: string;
}

// send invite
export const sendInvite = ({
  id,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SendInviteReques): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${id}/send`,
    method: 'post',
  });
};

export type TMergeTemplates = {
  inviteId: string;
  templates: string[];
  requests: string[];
  subject_template_id?: string;
  message_template_id?: string;
  reminder_settings_template_id?: string;
};

export const mergeTemplates = ({
  inviteId,
  ...data
}: TMergeTemplates): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/merge-templates`,
    data,
    method: 'post',
  });
};
