/* eslint-disable react/prop-types */
import { Player } from '@lottiefiles/react-lottie-player';
import { LoadingBox } from '@nx-fe/components';

interface LoadingProps {
  height?: number | string;
}

export const Loading: React.FC<LoadingProps> = ({ height = '100%' }) => {
  return (
    <LoadingBox height={height}>
      <Player
        autoplay
        loop
        src="/assets/initializing.json"
        style={{ height: '34px', width: '38px' }}
      ></Player>
    </LoadingBox>
  );
};
