import { colors, weights } from '@components/theme/gen2';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { rem } from 'polished';

export const Container = styled('div')({
  backgroundColor: colors.white,
  padding: rem(20),
  color: colors.grayDark2,
  display: 'flex',
  flexDirection: 'column',
  gap: rem(30),
});

export const TemplateHeadSection = styled('div')({});

export const TemplateFieldLine = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const TemplateHeadTitle = styled('div')({
  fontSize: rem(20),
  fontWeight: weights.medium,
  margin: 0,
  marginBottom: rem(20),
});

export const TemplateFieldLabel = styled('div')({
  fontSize: rem(14),
  color: colors.grayDark2,
  marginRight: rem(5),
  fontWeight: weights.medium,
  minWidth: rem(120),
  flexShrink: 0,
  gap: rem(5),
  display: 'flex',
  alignItems: 'center',
});

export const FieldCounter = styled('div')<{ $strong?: boolean }>(
  ({ $strong }) => ({
    fontSize: rem(12),
    color: colors.gray,
    // marginLeft: rem(10),
    flexShrink: 0,

    ...($strong && {
      color: colors.grayDark2,
    }),
  }),
);

export const ReminderButton = styled(Button)({
  gap: rem(5),
  marginLeft: rem(40),
  flexShrink: 0,
});

export const TemplateHeadTextField = styled(TextField)({
  maxWidth: rem(440),
  marginRight: rem(5),
});

export const TemplateDetails = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: rem(20),
});

export const SubmitContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(12),
  alignItems: 'flex-end',

  '& p': {
    maxWidth: rem(205),
    textAlign: 'right',
    fontSize: rem(12),
  },
});
