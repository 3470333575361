import { isEmpty } from 'lodash';
import { FieldValues } from 'react-hook-form';

export type ResponseErrors<TForm> = {
  errors: {
    [K in keyof TForm]?: string[];
  };
  message: string;
};

export type FormErrors<T> = {
  errors: {
    key: keyof T;
    type: string;
    message: string;
  }[];
  message: string;
};

export const mapFormErrors = <TForm extends FieldValues>(
  err: ResponseErrors<TForm>,
): FormErrors<TForm> | null => {
  if (isEmpty(err?.errors)) return null;

  const formErrors = Object.keys(err.errors).map((key) => {
    return {
      key: key as keyof TForm,
      type: 'manual',
      message: err.errors[key]?.join('\n') ?? '',
    };
  });

  return {
    errors: formErrors,
    message: err.message,
  };
};
