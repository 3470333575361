import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faBoxArchive,
  faCog,
  faEnvelopeOpen,
  faEnvelopes,
  faFiles,
  faLink,
  faPalette,
  faBriefcase,
  faSlidersH,
  faUser,
  faUsers,
  faAddressBook,
  faUsersRectangle,
} from '@fortawesome/pro-regular-svg-icons';
import { PermissionsKeys } from '@gen2/types/permissions';
import { Contacts } from './sub-menu/contacts';
import { InviteSettings } from './sub-menu/invite-settings';
import { Invites } from './sub-menu/invites';
import { Settings } from './sub-menu/settings';
import { Team } from './sub-menu/team';

export type MenuItem = {
  id: string;
  to?: string;
  title?: string;
  icon?: IconDefinition;
  featureFlag?: string | string[];
  permission?: PermissionsKeys | PermissionsKeys[]; // one of the key must be valid
};

export const mainMenu: MenuItem[] = [
  {
    id: 'invites',
    title: 'Invites',
    icon: faEnvelopes,
    permission: PermissionsKeys.LIST_INVITE,
  },
  {
    id: 'templates',
    title: 'Templates',
    to: '/templates',
    icon: faFiles,
    permission: PermissionsKeys.LIST_TEMPLATE,
  },
  {
    id: 'team',
    title: 'Team',
    icon: faUsers,
    permission: [PermissionsKeys.LIST_USER, PermissionsKeys.LIST_TEAM],
  },
  {
    id: 'contacts',
    title: 'Contacts',
    icon: faAddressBook,
    permission: PermissionsKeys.LIST_CONTACT,
  },
];

export const extras: MenuItem[] = [
  {
    id: 'settings',
    title: 'Settings',
    icon: faCog,
  },
];

// ===============================================
// Sub Menu
// ===============================================
export const subMenus: Record<MenuItem['id'], React.ReactNode> = {
  invites: <Invites />,
  templates: <div>Templates</div>,
  contacts: <Contacts />,
  updates: <div>Updates</div>,
  settings: <Settings />,
  inviteSettings: <InviteSettings />,
  team: <Team />,
};

export const invites: MenuItem[] = [
  {
    id: 'manageInvites',
    to: '/invite-listing',
    title: 'Manage Invites',
    icon: faEnvelopes,
    permission: PermissionsKeys.LIST_INVITE,
  },
  {
    id: 'draftInvites',
    to: '/draft-listing',
    title: 'Draft Invites',
    icon: faEnvelopeOpen,
    permission: PermissionsKeys.LIST_INVITE,
  },
  {
    id: 'archivedInvites',
    to: '/archived-listing',
    title: 'Archived Invites',
    icon: faBoxArchive,
    permission: PermissionsKeys.LIST_INVITE,
  },
  {
    id: 'collationHistory',
    to: '/invites/collation-history',
    title: 'Collation History',
    icon: faFiles,
    permission: PermissionsKeys.LIST_COLLATE_HISTORY,
  },
];

export const settings: MenuItem[] = [
  {
    id: 'account',
    title: 'Account',
    to: '/account',
    icon: faBriefcase,
    permission: PermissionsKeys.SHOW_CUSTOM_DOMAIN,
  },
  {
    id: 'branding',
    title: 'Branding',
    to: '/branding',
    icon: faPalette,
    permission: PermissionsKeys.LIST_BRANDING,
  },
  {
    id: 'integrations',
    title: 'Integrations',
    to: '/integrations',
    icon: faLink,
    permission: PermissionsKeys.CREATE_INVITE,
  },
  {
    id: 'preferences',
    title: 'Preferences',
    to: '/file-options',
    icon: faSlidersH,
    permission: PermissionsKeys.LIST_NAMING_CONVENTION,
  },
  {
    id: 'profile',
    to: '/user-settings',
    title: 'Profile',
    icon: faUser,
  },
];

export const settingsWithoutAccount = settings.slice(1);
export const team: MenuItem[] = [
  {
    id: 'manageTeamMembers',
    to: '/team-members',
    title: 'Team Members',
    icon: faUser,
    permission: PermissionsKeys.LIST_USER,
  },
  {
    id: 'manageTeams',
    to: '/teams',
    title: 'Teams',
    icon: faUsers,
    featureFlag: 'teams',
    permission: PermissionsKeys.LIST_TEAM,
  },
];

export const contacts: MenuItem[] = [
  {
    id: 'manageContacts',
    to: '/contacts',
    title: 'Contacts',
    icon: faAddressBook,
    permission: PermissionsKeys.LIST_CONTACT,
  },
  {
    id: 'manageContactGroups',
    to: '/contact-groups',
    title: 'Contact Groups',
    icon: faUsersRectangle,
    featureFlag: 'contact_groups',
    permission: PermissionsKeys.LIST_CONTACT_GROUP,
  },
];
