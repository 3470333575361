import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledAddContactForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  padding: `0 ${rem(20)}`,
  width: rem(676),
  maxWidth: rem(676),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
