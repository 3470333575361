import { colors, weights } from '@components/theme/gen2';
import { Box, Dialog, DialogContent, DialogTitle, styled } from '@mui/material';
import { rem } from 'polished';

interface ModalContentProps {
  step?: number;
}

export const MergeTemplateModalContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: rem(1210),
    minHeight: rem(745),

    [theme.breakpoints.down('md')]: {
      width: `calc(${rem(768)} - 32px)`,
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: rem(1210),
    },
  },
  '& strong': {
    textTransform: 'capitalize',
    fontWeight: weights.normal,
  },
  '& em': {
    fontWeight: weights.normal,
    fontStyle: 'normal',
    color: colors.blueDark1,
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
}));

export const HeaderTitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: rem(20),
  fontWeight: weights.semiBold,
  color: colors.grayDark3,
});

export const ModalHeader = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const HeaderActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: rem(10),
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    paddingBottom: 0,
    gap: rem(12),
    paddingRight: 0,
  },

  [theme.breakpoints.down('md')]: {
    '& > button': {
      padding: `${rem(4)} ${rem(8)}`,
      minHeight: rem(20),
    },
  },

  button: {
    flexShrink: 0,
  },
}));

export const ModalContent = styled(DialogContent)<ModalContentProps>(({ step = 1, theme }) => ({
  justifyContent: 'center',
  alignItems: 'flex-start',
  display: 'flex',
  // overflowX: 'scroll',
  gap: rem(20),
  padding: 0,

  [theme.breakpoints.down('md')]: {
    minWidth: `calc(${rem(768)} - ${rem(60)})`,
    justifyContent: 'flex-start'
  },
}));

export const TemplateCardContainer = styled(Box)<ModalContentProps>(({ theme }) => ({
  display: 'flex',
  gap: rem(20),
  padding: `${rem(15)} ${rem(19)}`
}));
