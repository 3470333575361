import { TFeatureFlags } from '@gen2/contexts';
import { useAuth } from '@gen2/hooks';
import { useMemo } from 'react';

export type TFeatureFlagsProps = {
  children: React.ReactNode;
  restriction: keyof TFeatureFlags | Array<keyof TFeatureFlags>;
};

export const FeatureFlags = ({ restriction, children }: TFeatureFlagsProps) => {
  const { featureFlags } = useAuth();

  const isAllowed = useMemo(() => {
    if (Array.isArray(restriction)) {
      return restriction.every((r) => featureFlags[r]);
    }

    return featureFlags[restriction];
  }, [featureFlags, restriction]);

  if (!isAllowed) {
    return null;
  }

  return (children as React.ReactElement);
};
