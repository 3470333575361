import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useAuth } from '@gen2/hooks';
import { TContact } from '@gen2/types/contact';
import { TContactGroup } from '@gen2/types/contact-group';
import { formatNames } from '@gen2/utils/name';
import { Button, Skeleton, Stack } from '@mui/material';
import * as EmailValidator from 'email-validator';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { AvartarWithNameEmail } from '../avatar/avatar';
import {
  BasePopupWithTabs,
  BasicPopupWithTabsProps,
  TabData,
} from '../base-popup-with-tabs/base-popup-with-tabs';
import { Item } from '../base-popup-with-tabs/base-popup-with-tabs.styled';
import TabPanel from '../base-popup-with-tabs/tab-panel';
import { Contacts, Name } from './contact-popup.styled';
import { useContactPopup } from './useContactPopup';

export interface ContactPopUpProps extends BasicPopupWithTabsProps {
  onOpenContact: (
    e: React.MouseEvent,
    mode: 'edit' | 'add',
    contact: TContact | null,
  ) => void;
  loading?: boolean;
  onSelectContact: (contact: TContact) => void;
  onSelectContactGroup: (group: TContactGroup) => void;
  resolveItems?: (items: TContact[]) => TContact[];
}

export const ContactPopUp = ({
  open,
  id,
  anchorEl,
  onOpenContact,
  onSelectContact,
  onSelectContactGroup,
  onClose,
  resolveItems,
  loading,
}: ContactPopUpProps) => {
  const { setPrePopulatedEmail } = useSendInviteStore();
  const { featureFlags } = useAuth();

  const {
    data_contacts,
    data_groups,
    isContactLoading,
    isGroupLoading,
    groupQuery,
    contactQuery,
    debouncedContactQuery,
    tabValue,
    handleSearchContacts,
    handleResetContacts,
    handleSearchGroups,
    handleResetGroups,
    handleTabChange,
  } = useContactPopup();

  // If an email is entered in the search field, and the contact is still not created,
  // the email field in the 'Create New Contact' pop-up modal should be pre-populated with the entered email.
  useEffect(() => {
    const isValidEmail = EmailValidator.validate(debouncedContactQuery);
    if (
      !isContactLoading &&
      isValidEmail &&
      isEmpty(data_contacts?.data?.contacts) &&
      !isNil(data_contacts?.data?.contacts)
    ) {
      setPrePopulatedEmail(debouncedContactQuery);
    }
  }, [
    data_contacts,
    setPrePopulatedEmail,
    debouncedContactQuery,
    isContactLoading,
  ]);

  const items = useMemo(() => {
    if (resolveItems) {
      return resolveItems(data_contacts?.data?.contacts || []);
    }

    return data_contacts?.data?.contacts || [];
  }, [data_contacts?.data?.contacts, resolveItems]);

  const renderContact = useCallback(
    (contact: TContact) => {
      if (loading) {
        return (
          <Stack data-cy="skeletion-loading" key={contact.id}>
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          </Stack>
        );
      }
      return (
        <Item
          key={contact.id}
          disabled={!!contact.disabled}
          onClick={() => onSelectContact(contact)}
          data-cy={`contact-item-${contact.id}`}
        >
          <AvartarWithNameEmail {...contact} />
        </Item>
      );
    },
    [loading, onSelectContact],
  );

  const renderContactGroup = useCallback(
    (group: TContactGroup) => {
      if (loading) {
        return (
          <Stack data-cy="group-skeletion-loading" key={group.id}>
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          </Stack>
        );
      }
      return (
        <Item
          key={group.id}
          data-cy={`contact-item-${group.id}`}
          onClick={() => onSelectContactGroup(group)}
        >
          <div>
            <Name>{group.name}</Name>
            <Contacts>{formatNames(group.contacts)}</Contacts>
          </div>
        </Item>
      );
    },
    [loading, onSelectContactGroup],
  );

  const tabDataArray: TabData[] = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Contact',
        featureFlag: 'invite_contact_group',
      },
      {
        id: 2,
        title: 'Contact Group',
        featureFlag: 'invite_contact_group',
      },
    ];
  }, []);

  const handleInnerClose = () => {
    onClose();
    handleResetContacts();
    handleResetGroups();
  };

  const filterTabDataDisplay = useMemo(() => {
    return tabDataArray.filter((item) => {
      if (!item.featureFlag) {
        return true;
      }
      return featureFlags?.[item.featureFlag as keyof typeof featureFlags];
    });
  }, [featureFlags, tabDataArray]);

  return (
    <BasePopupWithTabs
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleInnerClose}
      title="Add by"
      tabs={filterTabDataDisplay}
      hasLeftActionButton
      tabValue={tabValue}
      handleTabChange={handleTabChange}
      actionButton={
        <>
          <div>
            {tabValue === 1 && (
              <Button
                variant="contained"
                color="secondary"
                data-cy="create-new-contact"
                disabled={loading || isContactLoading}
                onClick={(e) => onOpenContact(e, 'add', null)}
              >
                Create New Contact
              </Button>
            )}
          </div>
          <Button
            variant="outlined"
            color="tertiary"
            data-cy="cancel"
            onClick={handleInnerClose}
          >
            Close
          </Button>
        </>
      }
      renderPanel={
        <>
          {tabValue === 1 && (
            <TabPanel
              subTitle="Search for individual contact by name or email"
              title="Contact"
              data={items as TContact[]}
              isLoading={isContactLoading}
              renderTabPanel={renderContact}
              handleSearch={handleSearchContacts}
              searchQuery={contactQuery}
              handleReset={handleResetContacts}
              placeholder="Search contacts by name or email"
              emptyMessage="No results for a Contact with this name or email."
              loading={loading}
            />
          )}
          {tabValue === 2 && (
            <TabPanel
              title="Contact Group"
              subTitle="Search for contact groups by name"
              data={data_groups?.data as TContactGroup[]}
              isLoading={isGroupLoading}
              handleSearch={handleSearchGroups}
              searchQuery={groupQuery}
              renderTabPanel={renderContactGroup}
              handleReset={handleResetGroups}
              placeholder="Search contact groups by name"
              emptyMessage="No results for a Contact Group with this name."
            />
          )}
        </>
      }
    />
  );
};
