import { TFollowUp } from '@gen2/api/follow-up/api';
import { useGetFollowUpsQuery } from '@gen2/api/follow-up/hooks';
import { useMemo } from 'react';

type UseFollowUpsReturn = {
  sent: TFollowUp[];
  draft: TFollowUp[];
  invalid: TFollowUp[];
};

export const useFollowUps = (id: string): UseFollowUpsReturn => {
  const { data: followUps } = useGetFollowUpsQuery({
    inviteId: id,
  });

  const followUpsData = useMemo(() => {
    if (!followUps || !followUps?.length) {
      return {
        sent: [],
        draft: [],
        invalid: [],
      };
    }

    // split follow ups into sent, draft and invalid
    return followUps.reduce(
      (acc, followUp) => {
        if (followUp.status === 'sent') {
          acc.sent.push(followUp);
        } else if (followUp.status === 'draft') {
          acc.draft.push(followUp);
        } else {
          acc.invalid.push(followUp);
        }

        return acc;
      },
      {
        sent: [] as TFollowUp[],
        draft: [] as TFollowUp[],
        invalid: [] as TFollowUp[],
      },
    );
  }, [followUps]);

  return followUpsData;
};
