import FormPhone from '@components/lib/form-phone/form-phone';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledFormTitle } from '@gen2/app/team-member/layout/team-member-create.styled';
import { useAuth } from '@gen2/hooks';
import { TAddContactForm, TContact } from '@gen2/types/contact';
import { contactSchema } from '@gen2/utils/form-schema/contact-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useEffect } from 'react';
import { SubmitHandler, useForm, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledAddContactForm } from './add-contact.styled';

export type AddContactPopupProps = {
  open: boolean;
  id?: string;
  onCreate: ({
    contact,
    form,
  }: {
    contact: TAddContactForm;
    form: { setError: UseFormSetError<TAddContactForm> };
  }) => void;
  onEdit?: ({
    contact,
    form,
  }: {
    contact: TAddContactForm;
    form: { setError: UseFormSetError<TAddContactForm> };
  }) => void;
  onClose: () => void;
  isAddContactLoading?: boolean;
  mode: 'add' | 'edit'; // Add or Edit mode
  currentContact?: TContact | null; // Optional contact data for edit mode
  prePopulatedEmail?: string; //The email field is pre-populated with the entered email
};

export default function AddContactPopup({
  open,
  id,
  onCreate,
  onClose,
  isAddContactLoading,
  mode,
  prePopulatedEmail,
}: AddContactPopupProps) {
  const { t } = useTranslation('validation');
  const { user: userMe } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm<TAddContactForm>({
    resolver: yupResolver(contactSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      country_iso2_code: 'AU',
      number: '',
      company: '',
      position: '',
    },
  });

  const phoneNumberField = register('number');
  const countryCodeField = register('country_iso2_code');
  const [countryIso2Code, phoneNumber] = watch(['country_iso2_code', 'number']);

  useEffect(() => {
    if (open && mode === 'add' && prePopulatedEmail) {
      setValue('email', prePopulatedEmail);
    }
  }, [open, prePopulatedEmail, setValue, mode]);

  useEffect(() => {
    if (userMe) {
      const userMeCountryIso2Code = userMe.organisations[0].country_iso2_code;

      if (userMeCountryIso2Code !== countryIso2Code) {
        setValue('country_iso2_code', userMeCountryIso2Code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMe, open]);

  // reset form on close
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [reset, open]);

  const onSubmit: SubmitHandler<TAddContactForm> = (data: TAddContactForm) => {
    // if (mode === 'edit') {
    //   return onEdit({
    //     contact: data,
    //     form: { setError },
    //   });
    // }
    onCreate({
      contact: data,
      form: { setError },
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      id={id}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="add-contact-popup"
    >
      <ModalCloseButton aria-label="close" onClick={handleClose}>
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <DialogTitle id="alert-dialog-title">Create New Contact</DialogTitle>
      <DialogContent dividers>
        <StyledAddContactForm noValidate data-cy="add-contact-form">
          <Stack direction="column" spacing={3}>
            <StyledFormTitle data-cy="contact-create-title">
              {t('form.contact_title', { ns: 'contacts' })}
            </StyledFormTitle>
            <Stack direction="row" spacing={2}>
              <TextField
                className="firstName"
                label={t('firstName.label')}
                variant="outlined"
                data-cy="firstName-input"
                fullWidth
                error={Boolean(errors.first_name)}
                helperText={
                  <span data-cy="first_name-error">
                    {errors.first_name?.message && t(errors.first_name.message)}
                  </span>
                }
                required
                {...register('first_name')}
              />
              <TextField
                className="lastName"
                label={t('lastName.label')}
                variant="outlined"
                fullWidth
                error={Boolean(errors.last_name)}
                helperText={
                  <span data-cy="last_name-error">
                    {errors.last_name?.message && t(errors.last_name.message)}
                  </span>
                }
                data-cy="last_name-input"
                required
                {...register('last_name')}
              />
            </Stack>
            <TextField
              label={t('email.label')}
              type="email"
              variant="outlined"
              fullWidth
              error={Boolean(errors.email)}
              data-cy="email-input"
              helperText={
                <span data-cy="email-error">
                  {errors.email?.message && t(errors.email.message)}
                </span>
              }
              required
              {...register('email')}
            />
            <FormPhone
              mobNumber={phoneNumber}
              codeFieldProps={{
                ...countryCodeField,
                defaultValue: countryIso2Code,
                value: countryIso2Code,
                onClick: (e) => e.stopPropagation(),
              }}
              phoneFieldProps={phoneNumberField}
              cypressId="phoneNumber"
              error={Boolean(errors.number)}
              errorMessage={
                <span data-cy="phoneNumber-error">
                  {errors.number?.message && t(errors.number.message)}
                </span>
              }
            />
            <StyledFormTitle data-cy="contact-create-title">
              {t('form.company_title', { ns: 'contacts' })}
            </StyledFormTitle>
            <TextField
              {...register('company')}
              id="company"
              className="company"
              label={t('company.label')}
              variant="outlined"
              error={!!errors.company}
              helperText={errors.company?.message && t(errors.company.message)}
              inputProps={{ 'data-cy': 'company' }}
            />
            <TextField
              {...register('position')}
              id="position"
              className="position"
              label={t('position.label')}
              variant="outlined"
              error={!!errors.position}
              helperText={
                errors.position?.message && t(errors.position.message)
              }
              inputProps={{ 'data-cy': 'position' }}
            />
          </Stack>
        </StyledAddContactForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="tertiary"
          onClick={onClose}
          data-cy="cancel-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={isAddContactLoading}
          data-cy="submit-btn"
          onClick={handleSubmit(onSubmit)}
          disabled={isAddContactLoading}
        >
          {mode === 'add' ? 'Create and add to invite' : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
