export const stringAvatar = (firstName: string | null | undefined, lastName: string | null | undefined) => {
    if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`
    }
    return '--'
}

export const formatNames = (
    names: { first_name: string; last_name: string }[],
    maxNames = 3
): string => {
    if (names.length > maxNames) {
        return `${names
            .slice(0, maxNames)
            .map((name) => `${name.first_name} ${name.last_name}`)
            .join(', ')}...`;
    } else {
        return names
            .map((name) => `${name.first_name} ${name.last_name}`)
            .join(', ');
    }
};
