import { useState, useCallback } from 'react';

export const useBasicTabsControl = (initialTabValue = 1) => {
  const [tabValue, setTabValue] = useState(initialTabValue);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [],
  );

  return {
    tabValue,
    handleTabChange,
  };
};
