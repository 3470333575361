import { useMutation, useQuery } from '@tanstack/react-query';
import { has } from 'lodash';
import {
  createFollowUp,
  getFollowUp,
  getFollowUps,
  sendFollowUp,
  TGetFollowUpQueryParams,
  updateFollowUp,
} from './api';

export enum FollowUpKeys {
  createFollowUp = 'createFollowUp',
  updateFollowUp = 'updateFollowUp',
  getFollowUp = 'getFollowUp',
  getFollowUps = 'getFollowUps',
  sendFollowUp = 'sendFollowUp',
}

export const useCreateFollowUpMutation = () =>
  useMutation({
    mutationKey: [FollowUpKeys.createFollowUp],
    mutationFn: createFollowUp,
  });

export const useGetFollowUpQuery = ({
  inviteId,
  followUpId,
}: TGetFollowUpQueryParams) =>
  useQuery({
    queryKey: [FollowUpKeys.getFollowUp, [inviteId, followUpId]],
    queryFn: async () => {
      try {
        const { data } = await getFollowUp({ inviteId, followUpId });

        if (data && has(data, 'data') && has(data.data, 'follow_up')) {
          return data.data.follow_up;
        }

        return null;
      } catch (error) {
        return null;
      }
    },
  });

export const useGetFollowUpsQuery = ({ inviteId }: { inviteId: string }) =>
  useQuery({
    enabled: !!inviteId,
    queryKey: [FollowUpKeys.getFollowUps, [inviteId]],
    queryFn: async () => {
      try {
        const { data } = await getFollowUps({ inviteId });

        if (data && has(data, 'data') && has(data.data, 'follow_ups')) {
          return data.data.follow_ups;
        }

        return null;
      } catch (error) {
        return null;
      }
    },
  });

export const useUpdateFollowUpMutation = () =>
  useMutation({
    mutationKey: [FollowUpKeys.updateFollowUp],
    mutationFn: updateFollowUp,
  });

export const useSendFollowUpMutation = () =>
  useMutation({
    mutationKey: [FollowUpKeys.sendFollowUp],
    mutationFn: sendFollowUp,
  });
