/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInviteQuery } from '@gen2/api/invites/hooks';
import { detectFormFields, getLiveForm } from '@gen2/api/live-form/api';
import { useSaveLiveFormMutation } from '@gen2/api/live-form/hooks';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useToast } from '@gen2/hooks';
import randomInteger from '@gen2/utils/number';
import { Box, Button, Dialog, MenuItem, Stack, TextField } from '@mui/material';
import { customDecode } from '@nx-fe/components';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import {
  HeaderTitle,
  StyledAside,
  StyledH3,
  StyledHeader,
  StyledHeaderActions,
  StyledP,
  StyledViewer,
  StyledWebViewerBox,
} from './peview-web-viewer-modal.styled';
import { disabledElementsForWebViewer } from './webviewer-config';
declare global {
  interface Window {
    PDFTronInstance: WebViewerInstance;
  }
}
export interface WebViewerModalProps {
  fileId: string;
  fileName: string;
  onClose: () => void;
  open: boolean;
  fileUrl: string;
}

interface Point {
  x?: number;
  y?: number;
}

const defaultAssignee = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
};

export const WebViewerModal = ({
  fileName,
  onClose,
  open,
  fileId,
  fileUrl,
}: WebViewerModalProps) => {
  const viewer = useRef(null);
  const [isHeaderInVisible, setIsHeaderInVisible] = useState(false);

  const { inviteId } = useParams<{
    inviteId: string;
  }>();

  const siStore = useSendInviteStore();
  const { data: invite } = useInviteQuery(inviteId || siStore?.invite?.id, {
    enabled: Boolean(inviteId || siStore?.invite?.id),
  });
  const contacts = useMemo(() => invite?.contacts || [], [invite]);

  const [isLoading, setIsLoading] = useState(true);
  const lastScrollTopRef = useRef(0);
  const { mutate } = useSaveLiveFormMutation();
  const toast = useToast();
  // Solution：solely to work around an issue that only appears in Development mode,
  // forcing WebViewer Initialization Only Occurs Once in Development build -- the Strict Mode
  const beenInitialised = useRef<boolean>(false);

  const initialAssignee = contacts.length > 0 ? contacts[0] : defaultAssignee;
  const [assignee, setAssignee] = useState(initialAssignee);

  const detectFields = async () => {
    window.PDFTronInstance.UI.openElements(['loadingModal']);
    setIsLoading(true);

    try {
      const { data } = await detectFormFields({
        fileId,
      });
      await window.PDFTronInstance.Core.annotationManager.importAnnotations(
        data.data.xfdf,
      );
    } catch (e) {
      toast.show({
        text: 'Something Wrong! Unable to detect fields',
        variant: 'error',
      });
    } finally {
      window.PDFTronInstance.UI.closeElements(['loadingModal']);
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    const handle = (el?: HTMLElement) => {
      if (fileUrl && el && !beenInitialised.current) {
        beenInitialised.current = true;
        WebViewer(
          {
            path: '/assets/lib/pdftron',
            licenseKey: customDecode(window.$zfeg48),
            initialDoc: fileUrl,
            disabledElements: disabledElementsForWebViewer,
          },
          el,
        ).then((instance) => {
          window.PDFTronInstance = instance;
          const { documentViewer, annotationManager } = instance.Core;
          const scrollViewElement = documentViewer.getScrollViewElement();

          // detect scroll direction to hide/show header
          const onScroll = () => {
            const currentScrollTop = scrollViewElement.scrollTop;
            const hasScrollbar =
              scrollViewElement.scrollHeight > scrollViewElement.clientHeight;

            const isScrollingDown = currentScrollTop > lastScrollTopRef.current;

            if (hasScrollbar) {
              setIsHeaderInVisible(isScrollingDown);
            } else {
              setIsHeaderInVisible(false);
            }

            lastScrollTopRef.current = currentScrollTop;
          };
          scrollViewElement.addEventListener('scroll', onScroll);

          // Fetching the XFDF data
          const documentLoadedListener = async () => {
            // API request to get the annotation XFDF string
            try {
              instance.UI.openElements(['loadingModal']);
              setIsLoading(true);
              const { data } = await getLiveForm(fileId);
              const xfdfString = data.data?.liveform?.fields?.xfdf_data;
              await annotationManager.importAnnotations(`${xfdfString}`);
            } catch (e) {
              console.error(e);
              if ((e as AxiosError).status === 500) {
                toast.show({
                  text: 'Internal Server Error',
                  variant: 'error',
                });
              } else if ((e as AxiosError).status === 422) {
                toast.show({
                  text: 'The selected type is invalid',
                  variant: 'error',
                });
              }
            } finally {
              instance.UI.closeElements(['loadingModal']);
              setIsLoading(false);
            }
          };

          documentViewer.addEventListener(
            'documentLoaded',
            documentLoadedListener,
          );
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setTimeout(() => handle(viewer.current!)); //dont chanage this
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    handle(viewer.current!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const onSave = async () => {
    const { annotationManager } = window.PDFTronInstance.Core;
    const xfdfString = await annotationManager.exportAnnotations({
      widgets: true,
      fields: true,
    });
    // API request to save the annotation XFDF string
    window.PDFTronInstance.UI.openElements(['loadingModal']);
    setIsLoading(true);
    mutate(
      {
        fileId,
        liveFormData: {
          xfdf_data: xfdfString,
          json_data: '',
          type: siStore.liveFormType,
        },
      },
      {
        onSuccess: () => {
          toast.show({
            text: 'File data saved successfully',
            variant: 'success',
          });
        },
        onError: (e) => {
          if ((e as AxiosError).status === 500) {
            toast.show({
              text: 'Internal Server Error',
              variant: 'error',
            });
          } else if ((e as AxiosError).status === 422) {
            toast.show({
              text: 'The selected type is invalid',
              variant: 'error',
            });
          }
        },
        onSettled: () => {
          window.PDFTronInstance.UI.closeElements(['loadingModal']);
          setIsLoading(false);
        },
      },
    );
  };

  const handleAssigneeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAssignee = contacts.find(
      (contact) => contact.email === event.target.value,
    );
    setAssignee(selectedAssignee || defaultAssignee);
  };

  const addField = (
    type: any,
    point: Point = {},
    name = '',
    value = '',
    flag = '',
  ) => {
    const { annotationManager, documentViewer, Annotations, Math } =
      window.PDFTronInstance.Core;
    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!point.x && page.first == null) {
      return; //don't add field to an invalid page location
    }
    const page_idx =
      page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoomLevel();

    const textAnnot = new Annotations.FreeTextAnnotation();
    textAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    textAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      textAnnot.Width = 50.0 / zoom;
      textAnnot.Height = 250.0 / zoom;
    } else {
      textAnnot.Width = 250.0 / zoom;
      textAnnot.Height = 50.0 / zoom;
    }
    textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
    textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

    textAnnot.setPadding(new Math.Rect(0, 0, 0, 0));
    if (siStore.liveFormType === 'multi') {
      textAnnot.setCustomData('custom', `${assignee.email}_${type}_`);
      textAnnot.setCustomData('type', `${type}`);
      textAnnot.setCustomData(
        'user',
        `${assignee.first_name}_${assignee.last_name}_${type}_`,
      );
    } else {
      textAnnot.setCustomData('custom', `${type}__FIELD_${randomInteger(100)}`);
      textAnnot.setCustomData('type', `${type}`);
      textAnnot.setCustomData('user', `${type}__FIELD`);
    }

    // set the type of annot
    textAnnot.setContents(textAnnot.getCustomData('custom'));
    textAnnot.FontSize = '' + 20.0 / zoom + 'px';
    textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
    textAnnot.TextColor = new Annotations.Color(0, 165, 228);
    textAnnot.StrokeThickness = 1;
    textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
    textAnnot.TextAlign = 'center';

    textAnnot.Author = annotationManager.getCurrentUser();

    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(textAnnot, {
      imported: true,
      isUndoRedo: true,
      autoFocus: true,
    });
    annotationManager.redrawAnnotation(textAnnot);
    annotationManager.selectAnnotation(textAnnot);
  };

  const applyFields = async () => {
    const { annotationManager, Annotations } = window.PDFTronInstance.Core;
    const fieldManager = annotationManager.getFieldManager();

    const annotationsList = annotationManager.getAnnotationsList();
    const annotsToDelete: any[] = [];
    const annotsToDraw: any[] = [];

    await Promise.all(
      annotationsList.map(async (annot, index) => {
        let widgetAnnot;
        let field;
        // if (typeof annot.getCustomData('custom') !== 'undefined') {
        // create a form field based on the type of annotation
        if (annot.getCustomData('type') === 'SIGNATURE') {
          field = new Annotations.Forms.Field(
            annot.getContents() + Date.now(),
            {
              type: 'Sig',
              tooltipName: annot.getCustomData('user'),
            },
          );
          widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
            appearance: '_DEFAULT',
            appearances: {
              _DEFAULT: {
                Normal: {
                  offset: {
                    x: 100,
                    y: 100,
                  },
                },
              },
            },
          });

          // set position
          widgetAnnot.PageNumber = annot.getPageNumber();
          widgetAnnot.X = annot.getX();
          widgetAnnot.Y = annot.getY();
          widgetAnnot.NoResize = true;
          widgetAnnot.rotation = annot.Rotation;
          if (annot.Rotation === 0 || annot.Rotation === 180) {
            widgetAnnot.Width = annot.getWidth();
            widgetAnnot.Height = annot.getHeight();
          } else {
            widgetAnnot.Width = annot.getHeight();
            widgetAnnot.Height = annot.getWidth();
          }

          // delete original annotation
          annotsToDelete.push(annot);

          // customize styles of the form field
          Annotations.WidgetAnnotation.prototype.getCustomStyles = (
            widget: typeof Annotations.WidgetAnnotation,
          ) => {
            if (widget instanceof Annotations.SignatureWidgetAnnotation) {
              return {
                border: '1px solid #e5ffa5',
              };
            }
            return {}; // Add this line to return an empty object if the condition is not met
          };

          Annotations.WidgetAnnotation.prototype.getCustomStyles(widgetAnnot);

          // draw the annotation in the viewer
          annotationManager.addAnnotation(widgetAnnot);
          fieldManager.addField(field);
          annotsToDraw.push(widgetAnnot);
        }
        // }
      }),
    );
    // delete old annotations
    annotationManager.deleteAnnotations(annotsToDelete, { force: true });

    // refresh viewer
    await annotationManager.drawAnnotationsFromList(annotsToDraw);
  };

  const applyAndSave = async () => {
    try {
      await applyFields();
      await onSave();
    } catch (e) {
      console.error(e);
      toast.show({
        text: 'Something went wrong! Reload the page and try again',
        variant: 'error',
      });
    }
  };

  return (
    <Dialog id="web-viewer-modal" fullScreen open={open} onClose={onClose}>
      <StyledHeader className={cn({ hidden: isHeaderInVisible })}>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button
            variant="contained"
            color="secondary"
            onClick={detectFields}
            disabled={isLoading}
          >
            <FontAwesomeIcon
              icon={regular('magic-wand-sparkles')}
              size="sm"
              style={{ marginRight: '5px' }}
            />{' '}
            AI Detect fields
          </Button>
          <Button variant="outlined" color="tertiary" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={applyAndSave}
            disabled={isLoading}
          >
            Save
          </Button>
        </StyledHeaderActions>
      </StyledHeader>
      <StyledWebViewerBox>
        <StyledAside>
          <StyledH3>Using our Form Builder</StyledH3>
          <StyledP>
            Draw form fields directly onto the PDF from the ‘Forms’ area or use
            our ‘AI Auto Apply’ to build your form.
          </StyledP>
          <StyledP>
            Lock the fields to the form use the ‘Apply Fields’ button and Click
            “Save” before you close. Edit fields by selecting ‘Forms’ again.
          </StyledP>
          <Stack>
            <Box paddingY={3}>
              <StyledH3>
                {siStore.liveFormType === 'multi'
                  ? 'Add signatures to your form'
                  : 'Signatures'}
              </StyledH3>
              {siStore.liveFormType === 'multi' && (
                <StyledP>
                  Choose who’s signature your want to add to the form:
                </StyledP>
              )}
            </Box>
            {siStore.liveFormType === 'multi' && (
              <Box paddingY={2}>
                <TextField
                  id="assignee-select"
                  inputProps={{
                    'data-cy': 'assignee-select',
                  }}
                  select
                  label="Adding signature for"
                  value={assignee.email}
                  fullWidth
                  onChange={handleAssigneeChange}
                >
                  {contacts.map((option) => (
                    <MenuItem key={option.id} value={option.email}>
                      {`${option.first_name} ${option.last_name}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => addField('SIGNATURE')}
                disabled={isLoading}
                sx={{ width: '100%' }}
              >
                Add Signature Field
              </Button>
            </Box>
          </Stack>
        </StyledAside>
        <StyledViewer ref={viewer} />
      </StyledWebViewerBox>
    </Dialog>
  );
};
