import SearchBar from '@components/lib/searchbar/searchbar';
import { Loading } from '../loader/loading';
import {
  Heading,
  List,
  StyledEmpty,
  TabPanel as TabPanelContainer,
} from './base-popup-with-tabs.styled';

export type TabPanelProps<T> = {
  data: T[] | undefined;
  renderTabPanel?: (data: T) => JSX.Element;
  isLoading: boolean;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery?: string;
  handleReset?: () => void;
  placeholder: string;
  emptyMessage: string;
  title: string;
  subTitle: string;
  loading?: boolean;
};

const TabPanel = <T,>({
  data,
  isLoading,
  handleSearch,
  searchQuery = '',
  handleReset,
  placeholder,
  emptyMessage,
  title,
  subTitle,
  renderTabPanel,
  loading,
}: TabPanelProps<T>) => {
  return (
    <TabPanelContainer>
      <Heading>
        <p data-cy="title">{title}</p>
        <span data-cy="subTitle">{subTitle}</span>
      </Heading>
      <SearchBar
        onChange={handleSearch}
        value={searchQuery}
        onReset={handleReset || (() => undefined)}
        placeholder={placeholder}
        id="search-bar"
        autoFocus
        mode="dark"
        isVariant={false}
        disabled={isLoading || loading}
      />
      {isLoading ? (
        <Loading height={300} />
      ) : data?.length ? (
        <List data-cy="search-result-list">
          {/* custom render function */}
          {renderTabPanel && data.map(renderTabPanel)}
        </List>
      ) : (
        <StyledEmpty data-cy="empty-message">{emptyMessage}</StyledEmpty>
      )}
    </TabPanelContainer>
  );
};

export default TabPanel;
