import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '@gen2/hooks';
import { useSidebarStore } from '@gen2/hooks/stores';
import { Permissions } from '@gen2/utils/permissions/permissions';
import { useMemo } from 'react';
import { MenuItem, settings, settingsWithoutAccount } from '../data';
import {
  StyledSidebarList,
  StyledSidebarListItem,
  StyledSidebarListItemLink,
  StyledSubMenuHeading,
} from '../sidebar.styled';

export const Settings = () => {
  const store = useSidebarStore();
  const { featureFlags } = useAuth();
  const onItemClick = (item: MenuItem) => {
    store.setOpen(false);

    if (item.id === 'home') return; // dashboard has no submenu

    store.setSelected(item);
    store.setSubMenuOpen(false);
  };

  const computedSettings = useMemo(() => {
    return featureFlags?.['custom_domain'] ? settings : settingsWithoutAccount;
  }, [featureFlags]);

  return (
    <StyledSidebarList data-cy="sidebar-settings">
      <StyledSubMenuHeading>Settings</StyledSubMenuHeading>
      {computedSettings.map((item) => {
        const Item = (
          <StyledSidebarListItem key={item.title}>
            <StyledSidebarListItemLink
              onClick={() => onItemClick(item)}
              to={item.to || ''}
              data-cy={`sidebar-settings-link-${item?.title?.toLowerCase()}`}
            >
              <FontAwesomeIcon size="lg" icon={item.icon || 'x'} />
              <span>{item.title}</span>
            </StyledSidebarListItemLink>
          </StyledSidebarListItem>
        );

        if (item.permission) {
          return (
            <Permissions key={item.title} allow={item.permission}>
              {Item}
            </Permissions>
          );
        }

        return Item;
      })}
    </StyledSidebarList>
  );
};
