import { colors, textSizes, typography, weights } from '@components/theme/gen2';
import { Box, FormControl, styled } from '@mui/material';
import { rem } from 'polished';

export const OtherSettingsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: rem(32),
  padding: `${rem(20)} ${rem(35)}`
}));

export const OtherSettingsDescription = styled('span')(({ theme }) => ({
  fontSize: textSizes.xxl,
  fontWeight: weights.medium
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(32)
}));

export const FieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: rem(20)
}));

export const FieldLabel = styled('span')(({ theme }) => ({
  fontSize: textSizes.xl,
  fontWeight: weights.semiBold
}));

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(20),
  alignSelf: 'stretch',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: rem(8),
  },
}));

export const RemindersWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: rem(80),
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
  padding: `0 ${rem(20)}`,

  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export const ErrorMessage = styled('span')({
  color: colors.danger,
  fontSize: rem(textSizes.sm),
  fontFamily: typography.fontFamily,
  fontWeight: weights.medium,
  lineHeight: 1.66,
  marginTop: rem(3),
});

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  maxWidth: rem(400),

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));