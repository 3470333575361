import { useSafeQuery } from '@gen2/hooks';
import { useMutation, UseQueryOptions } from '@tanstack/react-query';
import {
  createTemplate,
  createTemplateRequest,
  deleteTemplateRequest,
  deleteTemplates,
  duplicateTemplate,
  getTemplate,
  getTemplateRequests,
  getTemplates,
  renameTemplate,
  saveTemplate,
  selectTemplate,
  TGetTemplatesRequest,
  TGetTemplatesResponse,
  updateTemplate,
  updateTemplateRequest,
} from './api';

export enum TemplateKeys {
  saveTemplate = 'saveTemplate',
  getTemplates = 'getTemplates',
  selectTemplate = 'selectTemplate',
  renameTemplate = 'renameTemplate',
  deleteTemplates = 'deleteTemplates',
  duplicateTemplate = 'duplicateTemplate',
  createTemplate = 'createTemplate',
  updateTemplate = 'updateTemplate',
  getTemplate = 'getTemplate',
  getTemplateRequests = 'getTemplateRequests',
  createTemplateRequest = 'createTemplateRequest',
  updateTemplateRequest = 'updateTemplateRequest',
  deleteTemplateRequest = 'deleteTemplateRequest',
}

export const useSelectTemplateMutation = () =>
  useMutation({
    mutationFn: selectTemplate,
    mutationKey: [TemplateKeys.selectTemplate],
  });

export const useSaveTemplateMutation = () =>
  useMutation({
    mutationFn: saveTemplate,
    mutationKey: [TemplateKeys.saveTemplate],
  });

export const useTemplatesQuery = (
  params?: TGetTemplatesRequest,
  options?: UseQueryOptions<TGetTemplatesResponse['data']>,
) =>
  useSafeQuery(
    [TemplateKeys.getTemplates, ...(Object.values(params ?? {}) as [])],
    async (): Promise<TGetTemplatesResponse['data']> => {
      const res = await getTemplates(params);
      return res?.data?.data;
    },
    {
      ...options,
    },
  );

export const useRenameTemplateMutation = () =>
  useMutation({
    mutationFn: renameTemplate,
    mutationKey: [TemplateKeys.renameTemplate],
  });

export const useDeleteTemplatesMutation = () =>
  useMutation({
    mutationFn: deleteTemplates,
    mutationKey: [TemplateKeys.deleteTemplates],
  });

export const useDuplicateTemplateMutation = () =>
  useMutation({
    mutationFn: duplicateTemplate,
    mutationKey: [TemplateKeys.duplicateTemplate],
  });

export const useCreateTemplateMutation = () =>
  useMutation({
    mutationFn: createTemplate,
    mutationKey: [TemplateKeys.createTemplate],
  });

export const useUpdateTemplateMutation = () =>
  useMutation({
    mutationFn: updateTemplate,
    mutationKey: [TemplateKeys.updateTemplate],
  });

export const useGetTemplateQuery = (templateId: string) =>
  useSafeQuery(
    [TemplateKeys.getTemplate, templateId],
    async () => {
      const res = await getTemplate(templateId);
      return res?.data?.data?.template;
    },
    {
      enabled: !!templateId,
    },
  );

export const useGetTemplateRequestsQuery = (templateId: string) =>
  useSafeQuery(
    [TemplateKeys.getTemplateRequests, templateId],
    async () => {
      const res = await getTemplateRequests({ templateId });
      return res?.data?.data?.requests;
    },
    {
      enabled: !!templateId,
    },
  );

export const useCreateTemplateRequestMutation = () =>
  useMutation({
    mutationFn: createTemplateRequest,
    mutationKey: [TemplateKeys.createTemplateRequest],
  });

export const useUpdateTemplateRequestMutation = () =>
  useMutation({
    mutationFn: updateTemplateRequest,
    mutationKey: [TemplateKeys.updateTemplateRequest],
  });

export const useDeleteTemplateRequestMutation = () =>
  useMutation({
    mutationFn: deleteTemplateRequest,
    mutationKey: [TemplateKeys.deleteTemplateRequest],
  });
