import { SetupPlugin } from '@gen2/app/components/rich-text-editor/plugins/SetupPlugin/SetupPlugin';
import RichTextEditor from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { FormHelperText } from '@mui/material';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTemplateForm } from '../hooks';
import { MAX_MESSAGE_LENGTH } from '../schema';
import { useTemplateFormStore } from '../store';
import { TTemplateForm } from '../template-form';
import { MessageFieldCounter } from './template-message.styled';

export const TemplateMessage = () => {
  const { t } = useTranslation('templates');
  const {
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext<TTemplateForm>();
  const { onSave } = useTemplateForm();
  const { template } = useTemplateFormStore();
  const wRawMessage = watch('rawMessage', '');

  const onChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const root = $getRoot();

      const rawText = root.getTextContent();
      const rawHTML = $generateHtmlFromNodes(editor);

      // if no text set back to empty
      if (rawText.length === 0) {
        setValue('message', '');
        setValue('meta', '');
      } else {
        setValue('message', rawHTML);
        setValue('meta', JSON.stringify(editorState.toJSON()));
      }

      setValue('rawMessage', rawText);

      if (rawText) {
        trigger('rawMessage');
      }
    });
  };

  const onSetup = (editor: LexicalEditor) => {
    const editorState = editor.getEditorState();

    editorState.read(() => {
      const root = $getRoot();

      const rawText = root.getTextContent();
      const rawHTML = $generateHtmlFromNodes(editor);

      setValue('rawMessage', rawText);
      setValue('message', rawHTML);
    });
  };

  return (
    <div>
      <RichTextEditor
        dataCy="template-rich-text"
        label={t('form.message.label')}
        placeholder={t('form.message.placeholder') ?? ''}
        onBlur={onSave}
      >
        <OnChangePlugin onChange={onChange} />
        <SetupPlugin
          onSetup={onSetup}
          defaultEditorState={template?.meta || ''}
          maxLength={MAX_MESSAGE_LENGTH}
        />
      </RichTextEditor>
      <MessageFieldCounter $strong>
        {t('form.counter', {
          count: wRawMessage?.length || 0,
          limit: MAX_MESSAGE_LENGTH,
        })}
      </MessageFieldCounter>
      {errors.rawMessage?.message && (
        <FormHelperText error>{t(errors.rawMessage?.message)}</FormHelperText>
      )}
    </div>
  );
};
