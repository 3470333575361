import { useUpdateTemplateMutation } from '@gen2/api/templates/hooks';
import { useToast } from '@gen2/hooks';
import { mapFormErrors } from '@gen2/utils/error-handling/map-errors';
import { FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ValidationError } from 'yup';
import { activateTemplatePayload, editTemplatePayload } from './helpers';
import { templateFormSchema } from './schema';
import { TTemplateForm } from './template-form';

export const useTemplateForm = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const { getValues, setError, trigger } = useFormContext<TTemplateForm>();
  const { mutateAsync: updateTemplateMutation } = useUpdateTemplateMutation();
  const toast = useToast();
  const { t } = useTranslation('templates');
  const navigate = useNavigate();

  const mapValidationErrors = (errors: ValidationError) => {
    errors.inner?.forEach((item: ValidationError) => {
      const { type, path, message } = item;

      if (path) {
        return setError(path as FieldPath<TTemplateForm>, {
          type,
          message: message,
        });
      }
    });
  };

  const onSave = async () => {
    if (!templateId) return;

    const values = getValues();

    // Trigger validation to show/hide errors
    trigger();

    try {
      await templateFormSchema.validate(values, {
        abortEarly: false,
      });

      const payload = editTemplatePayload(templateId, values);

      await updateTemplateMutation(payload);

      toast.show({
        variant: 'success',
        text: t('form.save.success'),
      });
    } catch (error: any) {
      if (error?.inner) {
        mapValidationErrors(error);
        return;
      }

      const formErrors = mapFormErrors<TTemplateForm>(error.data);

      if (!formErrors) return;

      formErrors.errors.forEach((error) => {
        setError(error.key, { message: error.message });
      });

      toast.show({
        variant: 'error',
        text: t('form.save.failed'),
      });
    }
  };

  const onActivate = async () => {
    if (!templateId) return;
    const values = getValues();

    try {
      await updateTemplateMutation(activateTemplatePayload(templateId, values));

      toast.show({
        variant: 'success',
        text: t('form.activate.success'),
      });

      navigate('/templates');
    } catch (error: any) {
      toast.show({
        variant: 'error',
        text: t('form.activate.failed'),
      });
    }
  };

  return {
    onSave,
    onActivate,
  };
};
