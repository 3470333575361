import { colors, weights } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControlLabel, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledContainer = styled('div')({
  minHeight: 'calc(100vh - var(--header-height))',
  backgroundColor: colors.grayLight2,
  paddingTop: rem(36),
  paddingBottom: rem(36),
});

export const StyledAlertContainer = styled('div')(({ theme }) => ({
  marginBottom: rem(20),
  marginTop: rem(20),
  maxWidth: rem(860),
  marginLeft: rem(20),
  marginRight: rem(20),

  [theme.breakpoints.up('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const StyledSuccessAlertContainer = styled('div')(({ theme }) => ({
  marginBottom: rem(20),
  maxWidth: rem(860),
  marginLeft: rem(20),
  marginRight: rem(20),

  [theme.breakpoints.up('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const StyledIntDetails = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: rem(10),
  backgroundColor: colors.white,
  maxWidth: rem(860),
  marginLeft: rem(20),
  marginRight: rem(20),

  [theme.breakpoints.up('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const StyledIntDetailsHeader = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${colors.grayLight1}`,

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));

export const StyledIntDetailsHeaderLeft = styled('div')({
  gridColumn: '1 / 2',
  padding: `${rem(40)} ${rem(43)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.grayLight3,
});

export const StyledIntDetailsHeaderLeftImg = styled('img')({
  width: '100%',
  objectFit: 'contain',
});

export const StyledIntDetailsHeaderRight = styled('div')({
  gridColumn: '2 / 4',
  padding: `${rem(20)} ${rem(30)}`,
});

export const StyledIntDetailsHeaderRightH3 = styled('h3')({
  margin: 0,
  fontWeight: weights.semiBold,
});

export const StyledIntDetailsHeaderRightP = styled('p')({
  margin: 0,
});

export const StyledIntDetailsHeaderRightLink = styled('a')({
  margin: 0,
  marginTop: rem(10),
  display: 'inline-block',
  textDecoration: 'underline',
});

export const StyledIntDetailsBody = styled('div')({
  padding: rem(40),
});

export const StyledIntDetailsBodySection = styled('div')({
  marginBottom: rem(30),
  paddingBottom: rem(30),
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const StyledIntDetailsBodyGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledIntDetailsBodyGroupTitle = styled('h5')({
  margin: 0,
  fontWeight: weights.semiBold,
  fontSize: rem(15),
  marginBottom: rem(5),
});

export const StyledIntDetailsFormLabelControl = styled(FormControlLabel)({
  marginLeft: '0',
  display: 'flex',
  gap: rem(10),
});

export const StyledIntDetailsFolder = styled('div')(({ theme }) => ({
  marginTop: rem(20),

  display: 'flex',
  flexDirection: 'column',
  gap: rem(10),
  width: '100%',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: rem(10),
  },
}));

export const StyledIntDetailsFolderButton = styled(Button)({
  flexShrink: 0,
});

export const StyledIntDetailsConnectIcon = styled(FontAwesomeIcon)({
  width: rem(18),
});

export const StyledIntDetailsFooter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
});

export const FilePath = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
  marginRight: rem(20),
  overflow: 'hidden',

  '& > span': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export const ConnectButtonWrapper = styled('div')({
  width: 'fit-content',
});
