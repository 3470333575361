import { useSafeQuery } from '@gen2/hooks';
import { TContactGroup } from '@gen2/types/contact-group';
import { SearchCondition } from '@gen2/types/search-condition';
import { UseQueryOptions } from '@tanstack/react-query';
import { has } from 'lodash';
import { getContactGroupList } from './api';

export enum InviteContactGroupKeys {
  getContactGroups = 'getContactGroups',
}

export type TContactGroupResponse = {
  data: TContactGroup[];
} | null;

export const useContactGroupsQuery = (
  params: SearchCondition,
  options?: UseQueryOptions<TContactGroupResponse>,
) => {
  return useSafeQuery<TContactGroupResponse>(
    [InviteContactGroupKeys.getContactGroups, params['filter[search]'] ?? ''],
    async (): Promise<TContactGroupResponse> => {
      try {
        const { data } = await getContactGroupList(params);

        if (data && has(data, 'data')) {
          return { data: data.data.contact_groups as TContactGroup[] };
        }
      } catch (error) {
        return null;
      }
      return null;
    },
    options,
  );
};
