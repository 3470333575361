import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { ModalCloseButton } from '@nx-fe/components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderActions,
  HeaderTitle,
  MergeTemplateModalContainer,
  ModalContent,
  ModalHeader,
  TemplateCardContainer,
} from './merge-templates-modal.styled';
import { OtherSettings } from './other-settings/other-settings';
import { useMergeTemplateModalStore } from './store';
import { TemplateCard } from './template-card/template-card';

export const MergeTemplatesModal = () => {
  const { t } = useTranslation('templates');
  const toast = useToast();

  const [currentStep, setCurrentStep] = useState<{ step: number, stepString: string; }>({ step: 1, stepString: 'Requests' });

  const {
    templates,
    selectedRequests,
    perTemplateSelectedCount,
    isSubmitting,
    isSubmitted,
    reset,
    setIsSubmitting,
    setIsSubmitted,
    submitModal,
    isModalOpen,
    closeModal,
  } = useMergeTemplateModalStore();

  const resetState = useCallback(() => {
    reset();
    setCurrentStep({ step: 1, stepString: 'Requests' });
  }, [reset]);

  const handleClose = useCallback(() => {
    resetState();
    closeModal(false);
  }, [closeModal, resetState]);

  useEffect(() => {
    if (isSubmitted) {
      resetState();
      setIsSubmitted(false);
      submitModal();
    }
  }, [isSubmitted]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const handleChangeStep = useCallback((step = 1, stepString = 'Requests') => {
    if (step === 2) {
      if (perTemplateSelectedCount) {

        const zeroValueEntries = Object.entries(perTemplateSelectedCount).filter(([key, value]) => value === 0);

        if (zeroValueEntries.length > 0) {
          const zeroTemplates = zeroValueEntries.map(([zeroTemplate, value]) => {
            const templateName = templates.filter((template) => template.id === zeroTemplate).pop()?.name;
            return `"${templateName}"`;
          });

          const templateString = zeroTemplates.length < 2
            ? zeroTemplates.join(' & ')
            : zeroTemplates.slice(0, -1).join(', ') + ' & ' + zeroTemplates.slice(-1);;

          const message = zeroTemplates.length > 1
            ? `templates ${templateString}`
            : `template ${templateString}`;

          toast.show({
            text: t('mergeModal.requests.noRequestSelected', { templateString: message }),
            variant: 'error',
          });

          return;
        }
      }

      if (selectedRequests) {
        if (selectedRequests.length > 50) {
          toast.show({
            text: "You've exceeded the 50 request limit. Please deselect some requests to continue.",
            variant: 'error',
          });

          return;
        }
      }
    }

    setCurrentStep({ step, stepString });
  }, [perTemplateSelectedCount, selectedRequests, t, templates, toast]);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
  }, [setIsSubmitting]);


  const handleBackButton = useCallback(() => {
    if (currentStep.step === 2) {
      handleChangeStep(1, 'Requests');

      return;
    }

    handleClose();
  }, [currentStep.step, handleChangeStep, handleClose]);

  return (
    <MergeTemplateModalContainer
      data-cy="delete-dialog"
      open={isModalOpen}
      onClose={() => !isSubmitting ? handleClose() : ''}
      aria-labelledby="delete"
      aria-describedby="delete"
      disablePortal={false}
    >
      <ModalCloseButton
        data-cy="delete-dialog-close"
        aria-label="close"
        disabled={isSubmitting}
        onClick={() => !isSubmitting ? handleClose() : ''}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <ModalHeader>
        <HeaderTitle><span>{t('mergeModal.title', { ...currentStep })}</span></HeaderTitle>
        <HeaderActions>
          <LoadingButton
            onClick={handleBackButton}
            color="primary"
            variant="contained"
            data-cy="load-selected-template-btn"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('mergeModal.buttons.back')}
          </LoadingButton>
          {
            currentStep.step === 2
              ? (
                <LoadingButton
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                  data-cy="load-selected-templates-btn"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('mergeModal.buttons.load')}
                </LoadingButton>
              )
              : (
                <LoadingButton
                  onClick={() => handleChangeStep(2, 'Other Saved Settings')}
                  color="secondary"
                  variant="contained"
                  data-cy="next-btn"
                // loading={isInviteRequestsUpdating}
                >
                  {t('mergeModal.buttons.next')}
                </LoadingButton>
              )
          }

        </HeaderActions>
      </ModalHeader>
      <ModalContent dividers step={currentStep.step}>
        {
          currentStep.step === 1
            ? (
              <TemplateCardContainer>
                {
                  templates.length > 0 && templates.map(
                    (template, index) => (
                      <TemplateCard key={index}
                        template={template}
                        data-cy={`template-${index}`}
                      />
                    )
                  )
                }
              </TemplateCardContainer>
            )
            : (
              <OtherSettings />
            )
        }
      </ModalContent>
    </MergeTemplateModalContainer>
  );
};
