import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logoutUser } from '@gen2/api/auth/api';
import { handleAvatar } from '@gen2/app/components/avatar/avatar';
import { useSendInviteApi } from '@gen2/app/invites/send-invites/hooks';
import { useAuth, useRouter } from '@gen2/hooks';
import { useSidebarStore } from '@gen2/hooks/stores';
import { PermissionsKeys } from '@gen2/types/permissions';
import { Permissions } from '@gen2/utils/permissions/permissions';
import { Button, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountHeader,
  archivedListingHeader,
  brandingHeader,
  collationHistoryHeader,
  contactGroupsHeader,
  contactsHeader,
  createContactGroupsHeader,
  createContactsHeader,
  createInviteHeader,
  createMembersHeader,
  createTeamsHeader,
  createTemplateHeader,
  CustomHeader,
  dashBoardHeader,
  draftListingHeader,
  editContactGroupsHeader,
  editContactHeader,
  editInviteHeader,
  editTeamsHeader,
  emptyHeader,
  fileOptionHeader,
  integrationsHeader,
  manageInviteHeader,
  teamMembersHeader,
  teamsHeader,
  templatesHeader,
  updateMembersHeader,
  viewContactGroupsHeader,
  viewContactHeader,
  viewTeamMembersHeader,
  viewTeamsHeader,
  viewTemplateHeader,
} from './data';
import {
  HeaderTitle,
  StyledAvatar,
  StyledHeader,
  StyledHeaderActions,
  StyledNavLink,
  StyledSubMenuToggle,
  SubTitle,
} from './header.styled';

export const CustomizedHeader = ({
  headerTitle,
  subTitle = '',
}: CustomHeader) => {
  return (
    <div data-cy="header-breadcrumbs">
      <HeaderTitle data-cy="header-title">
        {headerTitle} <SubTitle data-cy="header-subtitle">{subTitle}</SubTitle>
      </HeaderTitle>
    </div>
  );
};

export const Header = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();
  const isEditInvite =
    router.pathname.startsWith('/send-invites/') &&
    router.pathname.split('/').length > 2;
  const isCreateInvite = router.pathname === '/send-invites';
  const isCreateTemplate =
    router.pathname.startsWith('/templates/') &&
    router.pathname.split('/').length > 2;

  // updateInviteHeader
  // NOTE: order matters
  const renderBreadcrumbs = useMemo(() => {
    if (router.pathname.includes('/invite-listing')) {
      return manageInviteHeader;
    } else if (isCreateInvite) {
      return createInviteHeader;
    } else if (isEditInvite) {
      return editInviteHeader;
    } else if (router.pathname.includes('/invites/collation-history')) {
      return collationHistoryHeader;
    } else if (router.pathname.includes('/integrations')) {
      return integrationsHeader;
    } else if (router.pathname.includes('/team-members/member/')) {
      return updateMembersHeader;
    } else if (router.pathname.includes('/team-members/member')) {
      return createMembersHeader;
    } else if (router.pathname.includes('/team-members/')) {
      return viewTeamMembersHeader;
    } else if (router.pathname.includes('/team-members')) {
      return teamMembersHeader;
    } else if (router.pathname.includes('/templates/view')) {
      return viewTemplateHeader;
    } else if (isCreateTemplate) {
      return createTemplateHeader;
    } else if (router.pathname.includes('/templates')) {
      return templatesHeader;
    } else if (router.pathname.includes('/teams/create')) {
      return createTeamsHeader;
    } else if (router.pathname.includes('/teams/view')) {
      return viewTeamsHeader;
    } else if (router.pathname.includes('/teams/edit')) {
      return editTeamsHeader;
    } else if (router.pathname.includes('/teams')) {
      return teamsHeader;
    } else if (router.pathname.includes('/dashboard')) {
      return dashBoardHeader;
    } else if (router.pathname.includes('/branding')) {
      return brandingHeader;
    } else if (router.pathname.includes('/file-options')) {
      return fileOptionHeader;
    } else if (router.pathname.includes('/draft-listing')) {
      return draftListingHeader;
    } else if (router.pathname.includes('/archived-listing')) {
      return archivedListingHeader;
    } else if (router.pathname.includes('/account')) {
      return accountHeader;
    } else if (router.pathname.includes('/contacts/create')) {
      return createContactsHeader;
    } else if (router.pathname.includes('/contacts/view')) {
      return viewContactHeader;
    } else if (router.pathname.includes('/contacts/edit')) {
      return editContactHeader;
    } else if (router.pathname.includes('/contacts')) {
      return contactsHeader;
    } else if (router.pathname.includes('/contact-groups/create')) {
      return createContactGroupsHeader;
    } else if (router.pathname.includes('/contact-groups/view')) {
      return viewContactGroupsHeader;
    } else if (router.pathname.includes('/contact-groups/edit')) {
      return editContactGroupsHeader;
    } else if (router.pathname.includes('/contact-groups')) {
      return contactGroupsHeader;
    } else {
      return emptyHeader;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);
  const sidebarStore = useSidebarStore();

  // Jump to new invite page and the page will do not wait for a response.
  const { onNavigateToDraft } = useSendInviteApi();

  const { logout, user } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleSubMenu = () => {
    sidebarStore.setSelected({ id: 'invites' });
    sidebarStore.setSubMenuOpen(!sidebarStore.subMenuOpen);
  };

  const onLogout = async () => {
    try {
      await logoutUser();
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      logout();
      handleClose();
    }
  };

  return (
    <StyledHeader data-cy="header">
      <StyledSubMenuToggle onClick={onToggleSubMenu}>
        <FontAwesomeIcon icon={regular('ellipsis-v')} />
      </StyledSubMenuToggle>
      <CustomizedHeader {...renderBreadcrumbs} />
      <StyledHeaderActions spacing={1} direction="row">
        <Permissions allow={PermissionsKeys.CREATE_INVITE}>
          <Button
            data-cy="create-invite-btn"
            variant="contained"
            color="secondary"
            onClick={() => onNavigateToDraft('initial')}
          >
            {!matches ? (
              t('header.btn', { ns: 'common' })
            ) : (
              <FontAwesomeIcon icon={regular('envelope-open')} />
            )}
          </Button>
        </Permissions>
        <StyledAvatar
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleProfileClick}
          data-cy="header-avatar-btn"
        >
          {handleAvatar(user)}
        </StyledAvatar>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem>
            <StyledNavLink to="/user-settings">Profile</StyledNavLink>
          </MenuItem>
          {user?.organisations && user.organisations.length > 1 && (
            <MenuItem>
              <StyledNavLink to="/switch-organisations">
                Switch Organisations
              </StyledNavLink>
            </MenuItem>
          )}
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </StyledHeaderActions>
    </StyledHeader>
  );
};
